import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apilink, prepareApiHeader } from "../../pages/General";

export const purchaseSlice = createApi({
    reducerPath: "purchaseApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${apilink}purchaseApi/graphql`,
        prepareHeaders: prepareApiHeader,
    }),
    tagTypes: ["purchase"],
    endpoints: (builder) => ({
        getPurchase: builder.query({
            query: (body) => ({
                url: "",
                method: "POST",
                body,
            }),
            providesTags: ["purchase"],
        }),
        purchaseAction: builder.mutation({
            query: (forms) => ({
                url: "",
                method: "POST",
                body: forms,
            }),
            invalidatesTags: ["purchase"],
        }),
    }),
});

export const { useGetPurchaseQuery, usePurchaseActionMutation } = purchaseSlice;
