import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import { Card, CardBody, CardHeader, Table } from "../../../components/base";
import { Container, Toolbar } from "../../../components/layout";
import { useGetCategoryQuery } from "../../../redux/catalog/CategoryApi";
import { replaceImage } from "../../General";

export const Category = () => {
    const navigate = useNavigate();
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
    ];

    const header = [
        {
            field: "category_name",
            title: "Category Name",
            sort: true,
            width: "95%",
        },
        {
            field: "status",
            title: "Status",
            sort: true,
            width: "5%",
            textAlign: "center",
        },
    ];

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState(["category_name", "ASC"]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPagination, setTotalPagination] = useState(0);
    const [data, setData] = useState([]);

    const {
        data: categoryData,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetCategoryQuery({
        endpoint: "get_category",
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        search,
        sort,
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (categoryData) {
                setTotalPagination(
                    Math.ceil(categoryData.total / activePagination)
                );
                categoryData.data.map((item) => {
                    getData.push({
                        id: item.category_id,
                        category_name: (
                            <div
                                className="d-flex align-items-center"
                                data-kt-ecommerce-edit-order-filter="product"
                                data-kt-ecommerce-edit-order-id="product_1"
                            >
                                <div className="symbol symbol-50px">
                                    <span
                                        className="symbol-label"
                                        style={{
                                            backgroundImage:
                                                "url(assets/media//stock/ecommerce/1dfd.gif)",
                                        }}
                                        onError={replaceImage}
                                    />
                                </div>
                                <div className="ms-5">
                                    <div className="text-gray-800 fs-5 fw-bold">
                                        {item.category_name}
                                    </div>
                                    <div className="fw-semibold fs-7">
                                        Description: {item.description || "---"}
                                    </div>
                                </div>
                            </div>
                        ),
                        status: (
                            <div
                                class={`badge badge-light-${
                                    item.status === "1" ? "success" : "danger"
                                }`}
                            >
                                {item.status === "1"
                                    ? "Published"
                                    : "Unpublished"}
                            </div>
                        ),
                    });
                });
            }
        }
        setIsLoading(fetchLoading);
        setData(getData);
    }, [categoryData, isSuccess, fetchLoading]);

    return (
        <>
            <Toolbar label="Category" breadcrumb={breadcrumb} />
            <Container>
                <Card>
                    <CardHeader
                        sx="border-0 pt-6"
                        action={
                            <Button onClick={() => navigate("/categoryAdd")}>
                                Add Category
                            </Button>
                        }
                    >
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input
                                type="text"
                                data-kt-customer-table-filter="search"
                                className="form-control form-control-solid w-250px ps-15"
                                placeholder="Search Category"
                                onChange={(e) => {
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        setSearch(e.target.value);
                                        setActive(1);
                                        setIsLoading(false);
                                    }, 1000);
                                }}
                            />
                        </div>
                    </CardHeader>
                    <CardBody sx="pt-0">
                        <Table
                            head={header}
                            body={data}
                            handleClick="categoryEdit"
                            isLoading={isLoading}
                            active={active}
                            setActive={setActive}
                            totalPage={totalPagination}
                            activePagination={activePagination}
                            setActivePagination={setActivePagination}
                            sort={sort}
                            setSort={setSort}
                        />
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};
