import Select from "react-select";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useGetCategoryQuery } from "../../../redux/catalog/CategoryApi";
import { useGetUnitQuery } from "../../../redux/catalog/UnitApi";

export const SidebarProductDetails = ({
    category,
    setCategory,
    unit,
    setUnit,
    searchTag,
    setSearchTag,
}) => {
    const [searchCategory, setSearchCategory] = useState("");
    const [searchUnit, setSearchUnit] = useState("");
    const [optionsCategory, setOptionsCategory] = useState("");
    const [optionsUnit, setOptionsUnit] = useState("");

    const { data: categoryData, isSuccess: categorySuccess } =
        useGetCategoryQuery({
            endpoint: "get_category_filter",
            token: true,
            search: searchCategory,
        });

    const { data: unitData, isSuccess: unitSuccess } = useGetUnitQuery({
        endpoint: "get_unit_filter",
        token: true,
        search: searchUnit,
    });

    useEffect(() => {
        let optionsData = [];
        if (categorySuccess) {
            if (categoryData) {
                categoryData.map((item) => {
                    optionsData.push({
                        value: item.category_id,
                        label: item.category_name,
                    });
                });
            }
        }
        setOptionsCategory(optionsData);
    }, [categorySuccess, categoryData]);

    useEffect(() => {
        let optionsData = [];
        if (unitSuccess) {
            if (unitData) {
                unitData.map((item) => {
                    optionsData.push({
                        value: item.unit_id,
                        label: item.unit_name,
                    });
                });
            }
        }
        setOptionsUnit(optionsData);
    }, [unitSuccess, unitData]);

    return (
        <Card className="card-flush py-4">
            <Card.Header>
                <div className="card-title">
                    <h2>Product Details</h2>
                </div>
            </Card.Header>
            <Card.Body className="pt-0">
                <Form>
                    <Form.Group className="mb-10 fv-row">
                        <Form.Label className="required form-label">
                            Categories
                        </Form.Label>
                        <Select
                            className="basic-single mb-2"
                            classNamePrefix="select"
                            value={category}
                            onChange={(e) => setCategory(e)}
                            options={optionsCategory}
                        />
                        <div className="text-muted fs-7 mb-7">
                            Add category to the product.
                        </div>
                    </Form.Group>
                </Form>
                <Form>
                    <Form.Group className="mb-10 fv-row">
                        <Form.Label className="required form-label">
                            Unit
                        </Form.Label>
                        <Select
                            className="basic-single mb-2"
                            classNamePrefix="select"
                            value={unit}
                            onChange={(e) => setUnit(e)}
                            options={optionsUnit}
                        />
                        <div className="text-muted fs-7 mb-7">
                            Add unit to the product.
                        </div>
                    </Form.Group>
                </Form>
                <Form>
                    <Form.Group className="mb-10 fv-row">
                        <Form.Label className="required form-label">
                            Search Tag
                        </Form.Label>
                        <Form.Control
                            className="form-control mb-2 form-control"
                            value={searchTag}
                            onChange={(e) => setSearchTag(e.target.value)}
                        />
                        <div className="text-muted fs-7 mb-7">
                            Add search tag to specific search item.
                        </div>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
};
