import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { Container, Toolbar } from "../../components/layout";
import { OrderDetails } from "./components/OrderDetails";
import { SelectProduct } from "./components/SelectProduct";
import { useGetSalesQuery } from "../../redux/transaction/SalesApi";
import { useForm } from "react-hook-form";

export const EditSales = () => {
    const { id } = useParams();

    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
        {
            label: "Sales",
            link: "/sales",
        },
    ];

    const { register, watch, setValue } = useForm({
        defaultValues: {
            date: "",
            customer: "",
            discount: 0,
            totalPrice: 0,
            receiptNumber: "",
            po: "",
            paid: 0,
            receipt: 0,
        },
    });

    const [productSelect, setProductSelect] = useState([]);

    const { data, isSuccess } = useGetSalesQuery({
        endpoint: "get_sales_details",
        sales_no: id,
        token: true,
    });

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                if (data.sales) {
                    let item = data.sales[0];
                    setValue(
                        "customer",
                        Number(item.sales_status) === 1
                            ? item.customer_id
                            : Number(item.sales_status) === 2
                            ? item.customer_name
                            : "Undefined"
                    );
                    setValue("date", item.sales_date);
                    setValue("discount", item.sales_discount);
                    setValue("receiptNumber", item.receipt_number);
                    setValue("totalPrice", item.grand_total);
                    setValue("receipt", item.receipt_name);
                    setValue("po", item.po_number);
                    setValue("paid", item.paid);
                }
                if (data.sales_detail.length > 0) {
                    setProductSelect(data.sales_detail);
                }
            }
        }
    }, [isSuccess, data]);

    return (
        <>
            <Toolbar label="Sales' Detail" breadcrumb={breadcrumb} />
            <Container>
                <div className="d-flex flex-column flex-lg-row">
                    <Row>
                        <Col xs={12} md={3}>
                            <OrderDetails viewing watch={watch} />
                        </Col>
                        <Col xs={12} md={9}>
                            <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                                <SelectProduct
                                    viewing
                                    productSelect={productSelect}
                                    setProductSelect={setProductSelect}
                                    totalPrice={watch("totalPrice")}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
