import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Button } from "react-bootstrap";

import { Card, CardBody, CardHeader, Table } from "../../components/base";
import { Container, Toolbar } from "../../components/layout";
import { useGetSalesQuery } from "../../redux/transaction/SalesApi";

export const SalesPerItem = () => {
    const navigate = useNavigate();
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
    ];

    const header = [
        {
            field: "sales_date",
            title: "Date",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "item_name",
            title: "Product",
            sort: true,
            width: "25%",
        },
        {
            field: "category_name",
            title: "Category",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "sales_rate",
            title: "Sell Rate",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "sales_qty",
            title: "Qty",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "customer_id",
            title: "Customer",
            sort: true,
            width: "20%",
        },
        {
            field: "sales_amount",
            title: "Total",
            sort: true,
            width: "15%",
            textAlign: "end",
        },
    ];

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState(["sales_date", "DESC"]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPagination, setTotalPagination] = useState(0);
    const [data, setData] = useState([]);

    const {
        data: categoryData,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetSalesQuery({
        endpoint: "get_sales_per_item",
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        search,
        sort,
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (categoryData) {
                setTotalPagination(
                    Math.ceil(categoryData.total / activePagination)
                );
                categoryData.data.map((item) => {
                    getData.push({
                        id: item.sales_no,
                        sales_date: moment(item.sales_date).format("ll"),
                        item_name: (
                            <div className="d-flex align-items-center">
                                <div className="ms-5">
                                    <div className="text-gray-800 text-hover-primary fs-5 fw-bold">
                                        {item.item_name}
                                    </div>
                                    <div className="fw-semibold fs-7">
                                        Brand: {item.brand || "---"}
                                    </div>
                                    <div className="fw-semibold fs-7">
                                        Type: {item.type || "---"}
                                    </div>
                                    <div className="text-muted fs-7">
                                        Part Number: {item.article_no || "---"}
                                    </div>
                                    {item.details !== "" && (
                                        <div className="text-muted fs-7">
                                            Additional Details: {item.details}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ),

                        receipt_number: item.receipt_number,
                        category_name: (
                            <Badge bg="primary">{item.category_name}</Badge>
                        ),
                        sales_rate: new Intl.NumberFormat("en-US").format(
                            item.sales_rate
                        ),
                        sales_qty: item.sales_qty,
                        customer_id: {
                            1: item.customer_id,
                            2: item.customer_name,
                        }[item.sales_status],
                        sales_amount: `₱ ${new Intl.NumberFormat(
                            "en-US"
                        ).format(item.sales_amount)}.00`,
                    });
                });
            }
        }
        setIsLoading(fetchLoading);
        setData(getData);
    }, [categoryData, isSuccess, fetchLoading]);

    return (
        <>
            <Toolbar label="Sales per Item" breadcrumb={breadcrumb} />
            <Container>
                <Card>
                    <CardHeader
                        sx="border-0 pt-6"
                        action={
                            <Button onClick={() => navigate("/salesAdd")}>
                                Add Sales
                            </Button>
                        }
                    >
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input
                                type="text"
                                data-kt-customer-table-filter="search"
                                className="form-control form-control-solid w-250px ps-15"
                                placeholder="Search Product"
                                onChange={(e) => {
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        setSearch(e.target.value);
                                        setActive(1);
                                        setIsLoading(false);
                                    }, 1000);
                                }}
                            />
                        </div>
                    </CardHeader>
                    <CardBody sx="pt-0">
                        <Table
                            head={header}
                            body={data}
                            handleClick={"salesDetail"}
                            isLoading={isLoading}
                            active={active}
                            setActive={setActive}
                            totalPage={totalPagination}
                            activePagination={activePagination}
                            setActivePagination={setActivePagination}
                            sort={sort}
                            setSort={setSort}
                        />
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};
