import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apilink, prepareApiHeader } from "../../pages/General";

export const userSlice = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${apilink}userApi/graphql`,
        // prepareHeaders(headers) {
        //     headers.set("withCredentials", "true");
        //     headers.set("credentials", "include");
        //     return headers;
        // },
        prepareHeaders: prepareApiHeader,
    }),
    tagTypes: ["user"],
    endpoints: (builder) => ({
        getData: builder.query({
            query: (body) => ({
                url: "",
                method: "POST",
                body,
            }),
            providesTags: ["user"],
        }),
        userAction: builder.mutation({
            query: (forms) => ({
                url: "",
                method: "POST",
                body: forms,
            }),
            invalidatesTags: ["user"],
        }),
    }),
});

export const { useGetDataQuery, useUserActionMutation } = userSlice;
