export const Card = (props) => {
    return (
        <div
            className={`card  ${props.flush ? "card-flush" : ""} ${
                props.bordered
                    ? "card-bordered"
                    : props.dashed
                    ? "card-dashed"
                    : "shadow-sm"
            } ${
                props.stretch
                    ? props.stretch !== true
                        ? `card-stretch-${props.stretch}`
                        : "card-stretch"
                    : ""
            } ${props.sx || ""}`}
            {...props}
        >
            {props.children}
        </div>
    );
};
