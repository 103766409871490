import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardBody, CardHeader, Table } from "../../../components/base";
import { Container, Toolbar } from "../../../components/layout";
import { Button } from "react-bootstrap";
import { useGetProductQuery } from "../../../redux/catalog/ProductApi";

export const Product = () => {
    const navigate = useNavigate();
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
    ];

    const header = [
        {
            field: "item_name",
            title: "Item",
            sort: true,
            width: "15%",
        },
        {
            field: "category_name",
            title: "Category",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "article_no",
            title: "Part Number",
            sort: true,
            width: "10%",
        },
        {
            field: "brand",
            title: "Brand",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "type",
            title: "Type",
            sort: true,
            width: "10%",
        },
        {
            field: "unit_name",
            title: "Unit",
            sort: true,
            width: "5%",
            textAlign: "center",
        },
        {
            field: "stock_qty",
            title: "Stock",
            sort: true,
            width: "5%",
            textAlign: "center",
        },
        {
            field: "purchase_rate",
            title: "Purchase Rate",
            sort: true,
            width: "15%",
            textAlign: "end",
        },
        {
            field: "stock_rate",
            title: "Stock Rate",
            sort: true,
            width: "15%",
            textAlign: "end",
        },
        {
            field: "action",
            title: "Action",
            sort: false,
            width: "5%",
            textAlign: "center",
        },
    ];

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState(["category_name", "ASC"]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPagination, setTotalPagination] = useState(0);
    const [data, setData] = useState([]);

    const {
        data: categoryData,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetProductQuery({
        endpoint: "get_product",
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        search,
        category: "", // add select filter to this
        sort,
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (categoryData) {
                setTotalPagination(
                    Math.ceil(categoryData.total / activePagination)
                );
                categoryData.data.map((item) => {
                    getData.push({
                        id: item.item_id,
                        item_name: item.item_name,
                        category_name: item.category_name,
                        article_no: item.article_no,
                        brand: item.brand,
                        type: item.type,
                        unit_name: item.unit_name,
                        stock_qty: (
                            <div
                                class={`badge badge-light-${
                                    item.stock_qty > 0 ? "primary" : "danger"
                                }`}
                            >
                                {item.stock_qty > 0
                                    ? item.stock_qty
                                    : "No Stock"}
                            </div>
                        ),
                        purchase_rate: item.purchase_rate,
                        stock_rate: item.stock_rate,
                    });
                });
            }
        }
        setIsLoading(fetchLoading);
        setData(getData);
    }, [categoryData, isSuccess, fetchLoading]);

    return (
        <>
            <Toolbar label="Product" breadcrumb={breadcrumb} />
            <Container>
                <Card>
                    <CardHeader
                        sx="border-0 pt-6"
                        // action={
                        //     <Button onClick={() => navigate("/productAdd")}>
                        //         Add Product
                        //     </Button>
                        // }

                        // to be follow
                    >
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input
                                type="text"
                                data-kt-customer-table-filter="search"
                                className="form-control form-control-solid w-250px ps-15"
                                placeholder="Search Product"
                                onChange={(e) => {
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        setSearch(e.target.value);
                                        setActive(1);
                                        setIsLoading(false);
                                    }, 1000);
                                }}
                            />
                        </div>
                    </CardHeader>
                    <CardBody sx="pt-0">
                        <Table
                            head={header}
                            body={data}
                            isLoading={isLoading}
                            handleClick="productEdit"
                            active={active}
                            setActive={setActive}
                            totalPage={totalPagination}
                            activePagination={activePagination}
                            setActivePagination={setActivePagination}
                            sort={sort}
                            setSort={setSort}
                        />
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};
