import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { Container, Toolbar } from "../../components/layout";
import { OrderDetails } from "./components/OrderDetails";
import { SelectProduct } from "./components/SelectProduct";
import { usePurchaseActionMutation } from "../../redux/transaction/PurchaseApi";

export const AddPurchase = () => {
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
        {
            label: "Purchase",
            link: "/purchase",
        },
    ];

    const navigate = useNavigate();

    const [addDetails, { data, isSuccess, isError }] =
        usePurchaseActionMutation();

    const [company, setCompany] = useState("");
    const [date, setDate] = useState("");
    const [purchaseNumber, setPurchaseNumber] = useState("");
    const [productSelect, setProductSelect] = useState([]);
    const [discount, setDiscount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const submitPurchase = () => {
        if (company !== "" && date !== "" && purchaseNumber !== "") {
            if (productSelect.length > 0) {
                addDetails({
                    company,
                    date,
                    purchaseNumber,
                    productSelect,
                    totalPrice,
                    discount,
                    endpoint: "add_purchase_details",
                    token: true,
                });
            } else {
                Swal.fire(
                    "Warning!",
                    "Need to select product to purchase.",
                    "warning"
                );
            }
        } else {
            Swal.fire(
                "Warning!",
                "Please fill in all the required fields (*).",
                "warning"
            );
        }
    };

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                Swal.fire(
                    "Saved!",
                    "Your file has been saved.",
                    "success"
                ).then(navigate("/purchase"));
            } else {
                Swal.fire(
                    "Something went wrong!",
                    "Please contact the administrator.",
                    "error"
                );
            }
        } else if (isError) {
            Swal.fire(
                "Something went wrong!",
                "Please contact the administrator.",
                "error"
            );
        }
    }, [isSuccess, isError, data]);

    useEffect(() => {
        let getTotal = 0;
        if (productSelect) {
            productSelect.map((item) => {
                getTotal = getTotal + item.total;
            });
        }

        setTotalPrice(getTotal - getTotal * (discount / 100));
    }, [productSelect, discount]);

    return (
        <>
            <Toolbar label="Add Purchase" breadcrumb={breadcrumb} />
            <Container>
                <div className="d-flex flex-column flex-lg-row">
                    <Row>
                        <Col xs={12} md={3}>
                            <OrderDetails
                                company={company}
                                setCompany={setCompany}
                                date={date}
                                setDate={setDate}
                                purchaseNumber={purchaseNumber}
                                setPurchaseNumber={setPurchaseNumber}
                                discount={discount}
                                setDiscount={setDiscount}
                            />
                        </Col>
                        <Col xs={12} md={9}>
                            <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                                <SelectProduct
                                    productSelect={productSelect}
                                    setProductSelect={setProductSelect}
                                    totalPrice={totalPrice}
                                />
                                <div className="d-flex justify-content-end">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Are you sure?",
                                                text: "You will be saving this purchase to database!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText:
                                                    "Yes, save it!",
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    submitPurchase();
                                                }
                                            });
                                        }}
                                    >
                                        <span className="indicator-label">
                                            Save Purchase
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
