import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { Container, Toolbar } from "../../components/layout";
import { OrderDetails } from "./components/OrderDetails";
import { SelectProduct } from "./components/SelectProduct";
import { useSalesActionMutation } from "../../redux/transaction/SalesApi";
import { useForm } from "react-hook-form";

export const AddSales = () => {
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
        {
            label: "Sales",
            link: "/sales",
        },
    ];

    const navigate = useNavigate();

    const [addDetails, { data, isSuccess, isError }] = useSalesActionMutation();

    const { register, watch, setValue } = useForm({
        defaultValues: {
            date: "",
            customer: "",
            discount: 0,
            totalPrice: 0,
            salesStatus: 0,
            receiptNumber: "",
            po: "",
            receipt: 0,
            // productSelect: [],
            // groupItem: [],
        },
    });

    const [productSelect, setProductSelect] = useState([]);

    const submitSales = (paid = 0) => {
        if (
            watch("customer") !== "" &&
            watch("date") !== "" &&
            watch("receiptNumber") !== "" &&
            watch("receipt") !== 0
        ) {
            if (productSelect.length > 0) {
                addDetails({
                    date: watch("date"),
                    customer: watch("customer"),
                    discount: watch("discount"),
                    totalPrice: watch("totalPrice"),
                    paid,
                    salesStatus: watch("salesStatus"),
                    receiptNumber: watch("receiptNumber"),
                    po: watch("po"),
                    receipt: watch("receipt"),
                    productSelect,
                    endpoint: "add_sales_details",
                    token: true,
                });
            } else {
                Swal.fire(
                    "Warning!",
                    "Need to select product to purchase.",
                    "warning"
                );
            }
        } else {
            Swal.fire(
                "Warning!",
                "Please fill in all the required fields (*).",
                "warning"
            );
        }
    };

    useEffect(() => {
        if (isSuccess) {
            if (data.result) {
                Swal.fire(
                    "Saved!",
                    "Your file has been saved.",
                    "success"
                ).then(navigate("/sales"));
            } else {
                Swal.fire("Warning!", data.msg, "warning");
            }
        } else if (isError) {
            Swal.fire(
                "Something went wrong!",
                "Please contact the administrator.",
                "error"
            );
        }
    }, [isSuccess, isError, data]);

    useEffect(() => {
        let getTotal = 0;
        if (productSelect) {
            productSelect.map((item) => {
                getTotal = getTotal + item.total;
            });
        }
        setValue("totalPrice", getTotal - watch("discount"));
    }, [productSelect, watch("discount")]);

    return (
        <>
            <Toolbar label="Add Sales" breadcrumb={breadcrumb} />
            <Container>
                <div className="d-flex flex-column flex-lg-row">
                    <Row>
                        <Col xs={12} md={3}>
                            <OrderDetails
                                register={register}
                                setValue={setValue}
                                watch={watch}
                            />
                        </Col>
                        <Col xs={12} md={9}>
                            <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                                {/* <GroupSelectProduct
                                    productSelect={productSelect}
                                    setProductSelect={setProductSelect}
                                /> */}
                                <SelectProduct
                                    productSelect={productSelect}
                                    setProductSelect={setProductSelect}
                                    totalPrice={watch("totalPrice")}
                                />
                                <div className="d-flex justify-content-end">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Are you sure?",
                                                text: "You will be saving this sales to database!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText:
                                                    "Yes, save it!",
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    Swal.fire({
                                                        title: "Enter the paid purchase",
                                                        input: "number",
                                                        showCancelButton: true,
                                                        confirmButtonText:
                                                            "Submit it!",
                                                        showLoaderOnConfirm: true,
                                                        allowOutsideClick: () =>
                                                            !Swal.isLoading(),
                                                    }).then((result) => {
                                                        if (
                                                            result.isConfirmed
                                                        ) {
                                                            submitSales(
                                                                result.value
                                                            );
                                                        }
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        <span className="indicator-label">
                                            Submit Sales
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
