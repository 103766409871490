import moment from "moment/moment";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Button } from "react-bootstrap";

import { Card, CardBody, CardHeader, Table } from "../../components/base";
import { Container, Toolbar } from "../../components/layout";
import { useGetSalesQuery } from "../../redux/transaction/SalesApi";

export const Sales = () => {
    const navigate = useNavigate();
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
    ];

    const header = [
        {
            field: "sales_date",
            title: "Date",
            sort: true,
            width: "15%",
            textAlign: "center",
        },
        {
            field: "receipt_name",
            title: "Type",
            sort: true,
            width: "15%",
            textAlign: "center",
        },
        {
            field: "receipt_number",
            title: "Invoice #",
            sort: true,
            width: "15%",
            textAlign: "center",
        },
        {
            field: "customer_id",
            title: "Customer",
            sort: true,
            width: "30%",
        },
        {
            field: "sales_status",
            title: "Status",
            sort: false,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "sales_amount_total",
            title: "Total",
            sort: true,
            width: "15%",
            textAlign: "end",
        },
    ];

    const [search, setSearch] = useState("");
    const [receipt, setReceipt] = useState("");
    const [sort, setSort] = useState(["sales_date", "DESC"]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPagination, setTotalPagination] = useState(0);
    const [data, setData] = useState([]);

    const {
        data: categoryData,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetSalesQuery({
        endpoint: "get_sales",
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        receipt,
        search,
        sort,
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (categoryData) {
                setTotalPagination(
                    Math.ceil(categoryData.total / activePagination)
                );
                categoryData.data.map((item) => {
                    getData.push({
                        id: item.sales_no,
                        sales_date: moment(item.sales_date).format("LL"),
                        receipt_name: (
                            <Badge
                                bg={
                                    {
                                        2: "primary",
                                        3: "success",
                                        4: "warning",
                                        5: "info",
                                        6: "secondary",
                                    }[item.receipt_id]
                                }
                                text={item.receipt_id === "4" ? "dark" : ""}
                            >
                                {item.receipt_name}
                            </Badge>
                        ),
                        receipt_number: item.receipt_number,
                        customer_id: {
                            1: item.customer_id,
                            2: item.customer_name,
                        }[item.sales_status],
                        sales_status: (
                            <Badge
                                bg={
                                    item.paid === "0"
                                        ? "danger"
                                        : item.balance !== "0" &&
                                          item.paid !== "0"
                                        ? "warning"
                                        : "info"
                                }
                                text={
                                    item.balance !== "0" && item.paid !== "0"
                                        ? "dark"
                                        : "white"
                                }
                            >
                                {item.paid === "0"
                                    ? "No Payment"
                                    : item.balance !== "0" && item.paid !== "0"
                                    ? "Pending"
                                    : "Paid"}
                            </Badge>
                        ),
                        sales_amount_total: `₱ ${item.sales_amount_total}`,
                    });
                });
            }
        }
        setIsLoading(fetchLoading);
        setData(getData);
    }, [categoryData, isSuccess, fetchLoading]);

    return (
        <>
            <Toolbar label="Sales" breadcrumb={breadcrumb} />
            <Container>
                <Card>
                    <CardHeader
                        sx="border-0 pt-6"
                        action={
                            <>
                                <Select
                                    className="w-200px"
                                    classNamePrefix="select"
                                    onChange={(e) => setReceipt(e)}
                                    isClearable
                                    placeholder={"Select Type"}
                                    options={[
                                        {
                                            value: 2,
                                            label: "Order Slip",
                                        },
                                        {
                                            value: 3,
                                            label: "Cash Invoice",
                                        },
                                        {
                                            value: 4,
                                            label: "Charge Invoice",
                                        },
                                        {
                                            value: 6,
                                            label: "Delivery Receipt (VAT)",
                                        },
                                    ]}
                                />
                                <Button onClick={() => navigate("/salesAdd")}>
                                    Add Sales
                                </Button>
                            </>
                        }
                    >
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input
                                type="text"
                                data-kt-customer-table-filter="search"
                                className="form-control form-control-solid w-250px ps-15"
                                placeholder="Search Sales"
                                onChange={(e) => {
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        setSearch(e.target.value);
                                        setActive(1);
                                        setIsLoading(false);
                                    }, 1000);
                                }}
                            />
                        </div>
                    </CardHeader>
                    <CardBody sx="pt-0">
                        <Table
                            head={header}
                            body={data}
                            handleClick={"salesDetail"}
                            isLoading={isLoading}
                            active={active}
                            setActive={setActive}
                            totalPage={totalPagination}
                            activePagination={activePagination}
                            setActivePagination={setActivePagination}
                            sort={sort}
                            setSort={setSort}
                        />
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};
