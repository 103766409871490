import { useEffect, useState } from "react";
import { Alert, Form, Modal, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import { useCustomerActionMutation } from "../../../redux/people/CustomerApi";

export const AddCustomer = ({ modalOpen, setModalOpen }) => {
    const [validated, setValidated] = useState(false);
    const [customerName, setCustomerName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [address, setAddress] = useState("");

    const [addDetails, { isSuccess, isError }] = useCustomerActionMutation();

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        console.log(form.checkValidity());
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            addDetails({
                customer_name: customerName,
                phone_no: phoneNo,
                address,
                endpoint: "add_customer_details",
                token: true,
            });
            setModalOpen(false);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success("Customer details has been added");
        } else if (isError) {
            toast.error("Failed to saved detail! Please contact the Admin");
        }
    }, [isSuccess, isError]);

    return (
        <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {validated && !customerName && (
                        <Alert variant="danger" className="mb-10">
                            Customer Name is required!
                        </Alert>
                    )}
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="validationCustom01">
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Customer name"
                                value={customerName}
                                onChange={(e) =>
                                    setCustomerName(e.target.value)
                                }
                            />
                            <Form.Control.Feedback type="invalid">
                                Customer Name is required!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="validationCustom01">
                            <Form.Label>Phone No.</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="09xxxxxxxxx"
                                value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="validationCustom01">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Address"
                                as="textarea"
                                rows={3}
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button type="submit">Save Customer</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
