import { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useProductActionMutation } from "../../../redux/catalog/ProductApi";

export const MoveQuantity = (props) => {
    const [data, setData] = useState([]);
    const [qty, setQty] = useState(0);

    const [
        updateDetails,
        {
            data: updateData,
            isSuccess: dataIsSuccess,
            isError: dataIsError,
            error: dataError,
        },
    ] = useProductActionMutation();

    const updateStock = (stock_qty, side_stock, id) => {
        updateDetails({
            stock_qty,
            side_stock,
            id,
            endpoint: "update_stock_details",
            token: true,
        });
    };

    useEffect(() => {
        if (dataIsSuccess) {
            if (updateData) {
                if (qty > 0) {
                    toast.success("Successfully updated");
                }
            } else {
                toast.error("Failed to update");
            }
        } else if (dataIsError) {
            toast.error("Failed to update");
        }
    }, [dataIsSuccess, dataIsError, dataError, updateData]);

    useEffect(() => {
        let getData = props.dataModal;
        if (getData) {
            setData(getData);
        }
    }, [props.dataModal]);

    const submitQty = () => {
        if (data.type === "side_stock") {
            if (Number(data.side_stock) >= Number(qty)) {
                updateStock(
                    Number(data.stock_qty) + Number(qty),
                    Number(data.side_stock) - Number(qty),
                    data.id
                );
            } else {
                toast.warning("Less stock quantity");
            }
        } else if (data.type === "stock_qty") {
            if (Number(data.stock_qty) >= Number(qty)) {
                updateStock(
                    Number(data.stock_qty) - Number(qty),
                    Number(data.side_stock) + Number(qty),
                    data.id
                );
            } else {
                toast.warning("Less stock quantity");
            }
        }
        setData([]);
        setQty(0);
        props.setShow(false);
    };

    return (
        <Modal
            {...props}
            size="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="d-flex justify-content-center flex-column align-items-center">
                    <h4>{data.title}</h4>
                    {data.text}
                    <br />
                    <br />
                    <Form.Control
                        type="number"
                        className="form-control form-control-solid w-300px ps-15"
                        onChange={(e) => {
                            if (e.target.value > 0) {
                                setQty(e.target.value);
                            } else {
                                toast.warning("Must be atleast 1 stock input");
                            }
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button
                    onClick={() => {
                        submitQty();
                    }}
                >
                    Move Stock
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setData([]);
                        setQty(0);
                        props.setShow(false);
                    }}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
