import { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Container, Toolbar } from "../../../../components/layout";
import { Sidebar } from "../../components/Sidebar";
import {
    useCategoryActionMutation,
    useGetCategoryQuery,
} from "../../../../redux/catalog/CategoryApi";

function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
}

export const EditCategory = () => {
    const { id } = useParams();
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
        {
            label: "Category",
            link: "/category",
        },
    ];

    const [isLoading, setLoading] = useState(false);
    const [categoryName, setCategoryName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");

    const { data, isSuccess } = useGetCategoryQuery({
        endpoint: "get_category_details",
        category_id: id,
        token: true,
    });

    const [updateDetails, { isSuccess: updateSuccess, isError: updateError }] =
        useCategoryActionMutation();

    useEffect(() => {
        if (isLoading) {
            simulateNetworkRequest().then(() => {
                updateDetails({
                    category_id: id,
                    category_name: categoryName,
                    description,
                    status,
                    endpoint: "update_category_details",
                    token: true,
                });
                setLoading(false);
            });
        }
    }, [isLoading]);

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setCategoryName(data.category_name);
                setDescription(data.description);
                setStatus(data.status);
            }
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (updateSuccess) {
            toast.success("Category details has been saved");
        } else if (updateError) {
            toast.error("Failed to saved detail! Please contact the Admin");
        }
    }, [updateSuccess, updateError]);

    const handleClick = () => setLoading(true);

    return (
        <>
            <Toolbar label="Edit Category" breadcrumb={breadcrumb} />
            <Container>
                <div className="d-flex flex-column flex-lg-row">
                    <Sidebar status={status} setStatus={setStatus} />
                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <Card className="card-flush py-4">
                            <Card.Header>
                                <div className="card-title">
                                    <h2>General</h2>
                                </div>
                            </Card.Header>
                            <Card.Body className="pt-0">
                                <Form>
                                    <Form.Group className="mb-10 fv-row">
                                        <Form.Label className="required form-label">
                                            Category Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Category name"
                                            value={categoryName}
                                            onChange={(e) =>
                                                setCategoryName(e.target.value)
                                            }
                                        />
                                        <div className="text-muted fs-7">
                                            A category name is required and
                                            recommended to be unique.
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-10 fv-row">
                                        <Form.Label className="form-label">
                                            Description
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            className="form-control mb-2"
                                            placeholder="Type your text here..."
                                            value={description}
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                        />
                                        <div className="text-muted fs-7">
                                            Set a description to the category
                                            for better visibility.
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                        <div className="d-flex justify-content-end">
                            <Button
                                variant="primary"
                                disabled={isLoading}
                                onClick={!isLoading ? handleClick : null}
                            >
                                {isLoading ? (
                                    <>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </>
                                ) : (
                                    "Save Changes"
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};
