import Flatpickr from "react-flatpickr";
import moment from "moment";
import Select from "react-select";
import { useEffect, useState } from "react";
import { Badge, Card, Form } from "react-bootstrap";

import { useGetPurchaseQuery } from "../../../redux/transaction/PurchaseApi";

export const OrderDetails = ({
    company,
    setCompany,
    date,
    setDate,
    purchaseNumber,
    setPurchaseNumber,
    discount,
    setDiscount,
    viewing,
}) => {
    const [search, setSearch] = useState("");
    const [options, setOptions] = useState("");

    const { data, isSuccess } = useGetPurchaseQuery({
        endpoint: "get_purchase_filter",
        token: true,
        search,
    });

    useEffect(() => {
        let optionsData = [];
        if (isSuccess) {
            if (data) {
                data.map((item) => {
                    optionsData.push({
                        value: item.company_id,
                        label: item.company_name,
                    });
                });
            }
        }
        setOptions(optionsData);
    }, [isSuccess, data]);

    return (
        <div className="d-flex flex-column gap-7 gap-lg-10 mb-7 me-lg-10">
            <Card className="card-flush py-4">
                <Card.Header>
                    <div className="card-title">
                        <h2>Order Details</h2>
                    </div>
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="d-flex flex-column gap-10">
                        <Form>
                            <Form.Group className="fv-row">
                                <Form.Label className="required form-label">
                                    Company Name
                                </Form.Label>
                                {!viewing ? (
                                    <>
                                        <Select
                                            className="basic-single mb-2"
                                            classNamePrefix="select"
                                            onChange={(e) =>
                                                setCompany(e.value)
                                            }
                                            options={options}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the purchaser to process.
                                        </div>
                                    </>
                                ) : (
                                    <h3 className="text-muted">{company}</h3>
                                )}
                            </Form.Group>
                        </Form>
                        <Form>
                            <Form.Group className="fv-row">
                                <Form.Label className="required form-label">
                                    Order Date
                                </Form.Label>
                                {!viewing ? (
                                    <>
                                        <Flatpickr
                                            data-enable-time
                                            value={date}
                                            className="form-control mb-2"
                                            onChange={(data) => {
                                                setDate(
                                                    moment(
                                                        new Date(data)
                                                    ).format("YYYY-MM-DD")
                                                );
                                            }}
                                            options={{
                                                enableTime: false,
                                                altFormat: "F j, Y",
                                            }}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the purchase date to process.
                                        </div>
                                    </>
                                ) : (
                                    <h3 className="text-muted">
                                        {moment(date).format("LL")}
                                    </h3>
                                )}
                            </Form.Group>
                        </Form>
                        <Form>
                            <Form.Group className="fv-row">
                                <Form.Label className="required form-label">
                                    Purchase Number
                                </Form.Label>
                                {!viewing ? (
                                    <>
                                        <Form.Control
                                            type="number"
                                            value={purchaseNumber}
                                            onChange={(e) =>
                                                setPurchaseNumber(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <div className="text-muted fs-7">
                                            Set the purchase number to process.
                                        </div>
                                    </>
                                ) : (
                                    <h3 className="text-muted">
                                        {purchaseNumber}
                                    </h3>
                                )}
                            </Form.Group>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
            <Card className="card-flush py-4">
                <Card.Header>
                    <div className="card-title">
                        <h2>Purchase Adjustment</h2>
                    </div>
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="d-flex flex-column gap-10">
                        <Form>
                            <Form.Group className="fv-row">
                                <Form.Label className="form-label">
                                    Discount <Badge bg="info">%</Badge>
                                </Form.Label>
                                {!viewing ? (
                                    <>
                                        <Form.Control
                                            type="number"
                                            value={discount}
                                            onChange={(e) =>
                                                setDiscount(e.target.value)
                                            }
                                        />
                                        <div className="text-muted fs-7">
                                            Set the discount of purchase to
                                            adjust the total purchase.
                                        </div>
                                    </>
                                ) : (
                                    <h3 className="text-muted">{discount}%</h3>
                                )}
                            </Form.Group>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};
