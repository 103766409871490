import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Table } from "../../../../../components/base";
import { useGetPurchaseQuery } from "../../../../../redux/people/PurchaserApi";

export const TransactionHistory = () => {
    const { id } = useParams();
    const header = [
        {
            field: "purchase_date",
            title: "Date",
            sort: true,
            width: "25%",
        },
        {
            field: "pur_no",
            title: "Purchase No.",
            sort: true,
            textAlign: "center",
            width: "15%",
        },
        {
            field: "status",
            title: "Status",
            sort: false,
            textAlign: "center",
            width: "10%",
        },
        {
            field: "purchase_amount_total",
            title: "Total",
            textAlign: "end",
            width: "15%",
        },
    ];

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState(["purchase_date", "DESC"]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    const [customerData, setCustomerData] = useState([]);

    const {
        data,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetPurchaseQuery({
        endpoint: "get_purchase_transaction_history",
        company_id: id,
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        search,
        sort,
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (data) {
                setTotalPage(Math.ceil(data.total / activePagination));
                data.data.map((item) => {
                    getData.push({
                        id: item.purchase_no,
                        purchase_date: moment(item.purchase_date).format("LL"),
                        pur_no: item.pur_no,
                        status:
                            item.due_amount !== "0.00" ? (
                                <Badge bg="warning">Pending</Badge>
                            ) : (
                                <Badge bg="primary">Confirmed</Badge>
                            ),
                        purchase_amount_total: `₱ ${item.purchase_amount_total}`,
                    });
                });
            }
        }
        setIsLoading(fetchLoading);
        setCustomerData(getData);
    }, [isSuccess, data, fetchLoading]);

    return (
        <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-0">
                <div className="card-title">
                    <h2>Transaction History</h2>
                </div>
            </div>
            <div className="card-body pt-0 pb-5">
                <Table
                    head={header}
                    body={customerData}
                    handleClick="purchaseDetails"
                    isLoading={isLoading}
                    active={active}
                    setActive={setActive}
                    totalPage={totalPage}
                    activePagination={activePagination}
                    setActivePagination={setActivePagination}
                    sort={sort}
                    setSort={setSort}
                />
            </div>
        </div>
    );
};
