export const Container = (props) => {
    return (
        <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
                id="kt_app_content_container"
                className="app-container container-xxl "
            >
                {props.children}
            </div>
        </div>
    );
};
