import { useEffect, useState } from "react";

import { Card, CardBody, CardHeader, Table } from "../../../components/base";
import { Container, Toolbar } from "../../../components/layout";
import { Button } from "react-bootstrap";
import { AddCustomer } from "../components/AddData";
import { useGetCustomerQuery } from "../../../redux/people/CustomerApi";
import { replaceImage } from "../../General";

export const Customer = () => {
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
    ];

    const header = [
        {
            field: "customer_name",
            title: "Customer Name",
            sort: true,
            width: "35%",
        },
        {
            field: "phone_no",
            title: "Phone No.",
            sort: true,
            width: "10%",
        },
        {
            field: "address",
            title: "Address",
            width: "55%",
        },
    ];

    const [modalOpen, setModalOpen] = useState(false);

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState(["customer_name", "ASC"]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPagination, setTotalPagination] = useState(0);
    const [data, setData] = useState([]);

    const {
        data: customerData,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetCustomerQuery({
        endpoint: "get_customer",
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        search,
        sort,
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (customerData) {
                setTotalPagination(
                    Math.ceil(customerData.total / activePagination)
                );
                customerData.data.map((item) => {
                    getData.push({
                        id: item.customer_id,
                        customer_name: (
                            <div
                                className="d-flex align-items-center"
                                data-kt-ecommerce-edit-order-filter="product"
                                data-kt-ecommerce-edit-order-id="product_1"
                            >
                                <div className="symbol symbol-50px">
                                    <span
                                        className="symbol-label"
                                        style={{
                                            backgroundImage:
                                                "url(assets/media//stock/ecommerce/1dfd.gif)",
                                        }}
                                        onError={replaceImage}
                                    />
                                </div>
                                <div className="ms-5">
                                    <div className="text-gray-800 fs-5 fw-bold">
                                        {item.customer_name}
                                    </div>
                                </div>
                            </div>
                        ),
                        phone_no: item.phone_no,
                        address: item.address,
                    });
                });
            }
        }
        setIsLoading(fetchLoading);
        setData(getData);
    }, [customerData, isSuccess, fetchLoading]);

    return (
        <>
            <Toolbar label="Customer" breadcrumb={breadcrumb} />
            <Container>
                <Card>
                    <CardHeader
                        sx="border-0 pt-6"
                        action={
                            <Button onClick={() => setModalOpen(true)}>
                                Add Customer
                            </Button>
                        }
                    >
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input
                                type="text"
                                data-kt-customer-table-filter="search"
                                className="form-control form-control-solid w-250px ps-15"
                                placeholder="Search Customers"
                                onChange={(e) => {
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        setSearch(e.target.value);
                                        setActive(1);
                                        setIsLoading(false);
                                    }, 1000);
                                }}
                            />
                        </div>
                    </CardHeader>
                    <CardBody sx="pt-0">
                        <Table
                            head={header}
                            body={data}
                            handleClick="customerDetails"
                            isLoading={isLoading}
                            active={active}
                            setActive={setActive}
                            totalPage={totalPagination}
                            activePagination={activePagination}
                            setActivePagination={setActivePagination}
                            sort={sort}
                            setSort={setSort}
                        />
                    </CardBody>
                </Card>
            </Container>

            <AddCustomer modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </>
    );
};
