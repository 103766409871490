import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apilink, prepareApiHeader } from "../../pages/General";

export const productSlice = createApi({
    reducerPath: "productApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${apilink}productApi/graphql`,
        prepareHeaders: prepareApiHeader,
    }),
    tagTypes: ["product"],
    endpoints: (builder) => ({
        getProduct: builder.query({
            query: (body) => ({
                url: "",
                method: "POST",
                body,
            }),
            providesTags: ["product"],
        }),
        productAction: builder.mutation({
            query: (forms) => ({
                url: "",
                method: "POST",
                body: forms,
            }),
            invalidatesTags: ["product"],
        }),
    }),
});

export const { useGetProductQuery, useProductActionMutation } = productSlice;
