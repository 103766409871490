import Swal from "sweetalert2";
import { useState, useEffect, useRef } from "react";
import {
    Accordion,
    Button,
    Col,
    Container,
    Form,
    Row,
    useAccordionButton,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { useGetProductQuery } from "../../../redux/catalog/ProductApi";
import { replaceImage } from "../../General";
import { GroupItem } from "./GroupItem";

export const SelectProduct = ({
    viewing,
    productSelect,
    setProductSelect,
    totalPrice,
}) => {
    const globalTimeout = useRef;

    const { register, watch, setValue } = useForm({
        defaultValues: {
            search: "",
            selectedId: [],
            isSearchTag: false,
            isLoading: false,
            modalShow: false,
            groupItem: { name: "", qty: 0, price: 0, list: [] },
        },
    });

    const [productList, setProductList] = useState([]);
    const [productSelectedList, setProductSelectedList] = useState([]);
    const [productSelectedListModal, setProductSelectedListModal] = useState(
        []
    );

    const { data, isSuccess } = useGetProductQuery(
        {
            endpoint: "get_product_filter",
            token: true,
            search: watch("search"),
            isSearchTag: watch("isSearchTag"),
            removeFromReturn: watch("selectedId").toString(),
        },
        {
            skip: watch("search") === "",
            refetchOnMountOrArgChange: true,
        }
    );

    const selectProductToList = (data) => {
        setProductSelect((oldArray) => [...oldArray, data]);
        if (!data.isGroup) {
            let newData = watch("selectedId");
            newData.push(data.id);
            setValue("selectedId", newData);
        }
    };

    const unSelectProductToList = (data) => {
        setProductSelect((oldArray) =>
            oldArray.filter((item) => item.id !== data.id)
        );
        let newData = watch("selectedId");
        newData = newData.filter((item) => {
            return item !== data.id;
        });
        setValue("selectedId", newData);
    };

    const updateDetails = (data) => {
        setProductSelect((oldArray) => {
            return oldArray.map((item) => {
                return item.id === data.id
                    ? { ...item, details: data.details }
                    : item;
            });
        });
    };

    const updateQty = (data) => {
        setProductSelect((oldArray) => {
            return oldArray.map((item) => {
                return item.id === data.id
                    ? { ...item, qty: data.qty, total: data.qty * item.price }
                    : item;
            });
        });
    };

    const updatePrice = (data) => {
        setProductSelect((oldArray) => {
            return oldArray.map((item) => {
                return item.id === data.id
                    ? {
                          ...item,
                          price: data.price,
                          total: item.qty * data.price,
                      }
                    : item;
            });
        });
    };

    // Modal Function
    const selectProductToModalList = (data) => {
        let itemIds = watch("groupItem.list").map((x) => x.id); // get all id in object of arrays

        if (itemIds.includes(data.id)) {
            setValue(
                "groupItem.list",
                watch("groupItem.list").filter((item) => item !== data)
            );
            // setGroupItem((oldArray) =>
            //     oldArray.filter((item) => item !== data)
            // );
        } else {
            let newData = watch("groupItem.list");
            newData.push(data);
            setValue("groupItem.list", newData);
            // setGroupItem((oldArray) => [...oldArray, data]);
        }
    };

    const saveNewGroup = () => {
        let itemIds = watch("groupItem.list").map((x) => x.id);
        setProductSelect((oldArray) =>
            oldArray.filter((item) => !itemIds.includes(item.id))
        );
        setValue(
            "selectedId",
            watch("selectedId").filter(
                (oldArray) => !itemIds.includes(oldArray)
            )
        );
        // setSelectedId((oldArray) =>
        //     oldArray.filter((item) => !itemIds.includes(item))
        // );
        setValue("modalShow", false);

        selectProductToList({
            //   id: item.item_id,
            //   stockId: item.stock_no,
            item: watch("groupItem.name"),
            type: "",
            brand: "",
            partNumber: "",
            category: "GROUP ITEM",
            qty: watch("groupItem.qty"),
            price: watch("groupItem.price"),
            total: watch("groupItem.price") * watch("groupItem.qty"),
            isGroup: true,
            itemList: watch("groupItem"),
            details: "",
        });
        setTimeout(() => {
            setValue("groupItem", { name: "", qty: 0, price: 0, list: [] });
        }, 1000);
    };

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log("totally custom!")
        );

        return (
            <div
                onClick={decoratedOnClick}
                onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "#80808040";
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "white";
                }}
                style={{
                    cursor: "pointer",
                    padding: "10px 0",
                }}
            >
                {children}
            </div>
        );
    }

    useEffect(() => {
        let getDisplay = [];

        if (productSelect.length > 0) {
            productSelect.map((item, index) => {
                getDisplay.push(
                    <div
                        key={index}
                        className=" my-2"
                        data-kt-ecommerce-edit-order-filter="product"
                        data-kt-ecommerce-edit-order-id="product_1"
                        style={{
                            padding: 10,
                        }}
                    >
                        {item.category === "GROUP ITEM" ? (
                            <Accordion flush>
                                <Accordion.Item eventKey={index}>
                                    <CustomToggle eventKey={index}>
                                        <Row>
                                            <Col xxl={4}>
                                                <div className="d-flex align-items-center">
                                                    <a className="symbol symbol-50px">
                                                        <span
                                                            className="symbol-label"
                                                            style={{
                                                                backgroundImage:
                                                                    "url(assets/media//stock/ecommerce/1dfd.gif)",
                                                            }}
                                                            onError={
                                                                replaceImage
                                                            }
                                                        />
                                                    </a>
                                                    <div className="ms-5">
                                                        <a className="text-gray-800 fs-5 fw-bold">
                                                            {item.item}
                                                        </a>
                                                        {item.category !==
                                                            "GROUP ITEM" && (
                                                            <>
                                                                <div className="fw-semibold fs-7">
                                                                    Brand:{" "}
                                                                    {item.brand ||
                                                                        "---"}
                                                                </div>
                                                                <div className="fw-semibold fs-7">
                                                                    Type:{" "}
                                                                    {item.type ||
                                                                        "---"}
                                                                </div>
                                                                <div className="text-muted fs-7">
                                                                    Part Number:{" "}
                                                                    {item.partNumber ||
                                                                        "---"}
                                                                </div>
                                                                {item.details &&
                                                                    !viewing && (
                                                                        <div className="text-muted fs-7">
                                                                            Additional
                                                                            Details:{" "}
                                                                            <div
                                                                                className="btn btn-link btn-color-info btn-active-color-primary"
                                                                                onClick={() =>
                                                                                    Swal.fire(
                                                                                        {
                                                                                            title: "Update the additional details",
                                                                                            input: "text",
                                                                                            showCancelButton: true,
                                                                                            confirmButtonText:
                                                                                                "Add the details!",
                                                                                            showLoaderOnConfirm: true,
                                                                                            allowOutsideClick:
                                                                                                () =>
                                                                                                    !Swal.isLoading(),
                                                                                        }
                                                                                    ).then(
                                                                                        (
                                                                                            result
                                                                                        ) => {
                                                                                            if (
                                                                                                result.isConfirmed
                                                                                            ) {
                                                                                                updateDetails(
                                                                                                    {
                                                                                                        id: item.id,
                                                                                                        details:
                                                                                                            result.value,
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.details
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </>
                                                        )}
                                                        {viewing &&
                                                            item.details !==
                                                                "" && (
                                                                <div className="text-muted fs-7">
                                                                    Additional
                                                                    Details:{" "}
                                                                    {
                                                                        item.details
                                                                    }
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col
                                                className="text-center m-auto"
                                                xxl={2}
                                            >
                                                <span class="badge badge-light-primary">
                                                    {item.category.toUpperCase()}
                                                </span>
                                            </Col>
                                            <Col
                                                className="text-center m-auto"
                                                xxl={1}
                                            >
                                                {!viewing ? (
                                                    <Form.Control
                                                        className="m-auto w-100"
                                                        value={item.qty}
                                                        onChange={(e) => {
                                                            updateQty({
                                                                id: item.id,
                                                                qty:
                                                                    Number(
                                                                        e.target
                                                                            .value
                                                                    ) > 0
                                                                        ? e
                                                                              .target
                                                                              .value
                                                                        : 1,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <span class="fw-bold ms-3">
                                                        {item.qty}
                                                    </span>
                                                )}
                                            </Col>
                                            <Col
                                                className="text-end m-auto"
                                                xxl={2}
                                            >
                                                {!viewing ? (
                                                    <Form.Control
                                                        className="m-auto w-75"
                                                        value={item.price}
                                                        onChange={(e) => {
                                                            updatePrice({
                                                                id: item.id,
                                                                price:
                                                                    Number(
                                                                        e.target
                                                                            .value
                                                                    ) > 0
                                                                        ? e
                                                                              .target
                                                                              .value
                                                                        : item.price,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <span class="fw-bold ms-3">
                                                        {item.price}
                                                    </span>
                                                )}
                                            </Col>
                                            <Col
                                                className="text-end m-auto"
                                                xxl={2}
                                            >
                                                <span class="fw-bold ms-3">
                                                    ₱{item.total}.00
                                                </span>
                                            </Col>
                                            {!viewing && (
                                                <Col
                                                    className="text-center m-auto"
                                                    xxl={1}
                                                >
                                                    <Button
                                                        variant="outline-danger"
                                                        onClick={() => {
                                                            unSelectProductToList(
                                                                {
                                                                    id: item.id,
                                                                    qty: item.qty,
                                                                    price: item.price,
                                                                    total: item.total,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <i class="fa-solid fa-trash"></i>
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                    </CustomToggle>
                                    <Accordion.Body
                                        style={{ padding: "0 50px" }}
                                    >
                                        {item.itemList.list.map((itemList) => {
                                            return (
                                                <Row>
                                                    <Col xxl={4}>
                                                        <div className="d-flex align-items-center">
                                                            <a className="symbol symbol-50px">
                                                                <span
                                                                    className="symbol-label"
                                                                    style={{
                                                                        backgroundImage:
                                                                            "url(assets/media//stock/ecommerce/1dfd.gif)",
                                                                    }}
                                                                    onError={
                                                                        replaceImage
                                                                    }
                                                                />
                                                            </a>
                                                            <div className="ms-5">
                                                                <a className="text-gray-800 fs-5 fw-bold">
                                                                    {
                                                                        itemList.item
                                                                    }
                                                                </a>
                                                                {itemList.category !==
                                                                    "GROUP ITEM" && (
                                                                    <>
                                                                        <div className="fw-semibold fs-7">
                                                                            Brand:{" "}
                                                                            {itemList.brand ||
                                                                                "---"}
                                                                        </div>
                                                                        <div className="fw-semibold fs-7">
                                                                            Type:{" "}
                                                                            {itemList.type ||
                                                                                "---"}
                                                                        </div>
                                                                        <div className="text-muted fs-7">
                                                                            Part
                                                                            Number:{" "}
                                                                            {itemList.partNumber ||
                                                                                "---"}
                                                                        </div>
                                                                        {itemList.details &&
                                                                            !viewing && (
                                                                                <div className="text-muted fs-7">
                                                                                    Additional
                                                                                    Details:{" "}
                                                                                    <div
                                                                                        className="btn btn-link btn-color-info btn-active-color-primary"
                                                                                        onClick={() =>
                                                                                            Swal.fire(
                                                                                                {
                                                                                                    title: "Update the additional details",
                                                                                                    input: "text",
                                                                                                    showCancelButton: true,
                                                                                                    confirmButtonText:
                                                                                                        "Add the details!",
                                                                                                    showLoaderOnConfirm: true,
                                                                                                    allowOutsideClick:
                                                                                                        () =>
                                                                                                            !Swal.isLoading(),
                                                                                                }
                                                                                            ).then(
                                                                                                (
                                                                                                    result
                                                                                                ) => {
                                                                                                    if (
                                                                                                        result.isConfirmed
                                                                                                    ) {
                                                                                                        updateDetails(
                                                                                                            {
                                                                                                                id: itemList.id,
                                                                                                                details:
                                                                                                                    result.value,
                                                                                                            }
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            itemList.details
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </>
                                                                )}
                                                                {itemList.details !==
                                                                    "" && (
                                                                    <div className="text-muted fs-7">
                                                                        Additional
                                                                        Details:{" "}
                                                                        {
                                                                            itemList.details
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className="text-center m-auto"
                                                        xxl={2}
                                                    >
                                                        <span class="badge badge-light-primary">
                                                            {itemList.category.toUpperCase()}
                                                        </span>
                                                    </Col>
                                                    <Col
                                                        className="text-center m-auto"
                                                        xxl={1}
                                                    >
                                                        <span class="fw-bold ms-3">
                                                            QTY: {itemList.qty}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        ) : (
                            <Row>
                                <Col xxl={4}>
                                    <div className="d-flex align-items-center">
                                        <a className="symbol symbol-50px">
                                            <span
                                                className="symbol-label"
                                                style={{
                                                    backgroundImage:
                                                        "url(assets/media//stock/ecommerce/1dfd.gif)",
                                                }}
                                                onError={replaceImage}
                                            />
                                        </a>
                                        <div className="ms-5">
                                            <a className="text-gray-800 fs-5 fw-bold">
                                                {item.item}
                                            </a>
                                            {item.category !== "GROUP ITEM" && (
                                                <>
                                                    <div className="fw-semibold fs-7">
                                                        Brand:{" "}
                                                        {item.brand || "---"}
                                                    </div>
                                                    <div className="fw-semibold fs-7">
                                                        Type:{" "}
                                                        {item.type || "---"}
                                                    </div>
                                                    <div className="text-muted fs-7">
                                                        Part Number:{" "}
                                                        {item.partNumber ||
                                                            "---"}
                                                    </div>
                                                    {item.details &&
                                                        !viewing && (
                                                            <div className="text-muted fs-7">
                                                                Additional
                                                                Details:{" "}
                                                                <div
                                                                    className="btn btn-link btn-color-info btn-active-color-primary"
                                                                    onClick={() =>
                                                                        Swal.fire(
                                                                            {
                                                                                title: "Update the additional details",
                                                                                input: "text",
                                                                                showCancelButton: true,
                                                                                confirmButtonText:
                                                                                    "Add the details!",
                                                                                showLoaderOnConfirm: true,
                                                                                allowOutsideClick:
                                                                                    () =>
                                                                                        !Swal.isLoading(),
                                                                            }
                                                                        ).then(
                                                                            (
                                                                                result
                                                                            ) => {
                                                                                if (
                                                                                    result.isConfirmed
                                                                                ) {
                                                                                    updateDetails(
                                                                                        {
                                                                                            id: item.id,
                                                                                            details:
                                                                                                result.value,
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        item.details
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                </>
                                            )}

                                            {!item.details && !viewing && (
                                                <div
                                                    className="btn btn-link btn-color-info btn-active-color-primary me-5 mb-2"
                                                    onClick={() =>
                                                        Swal.fire({
                                                            title: "Enter the additional details",
                                                            input: "text",
                                                            showCancelButton: true,
                                                            confirmButtonText:
                                                                "Add the details!",
                                                            showLoaderOnConfirm: true,
                                                            allowOutsideClick:
                                                                () =>
                                                                    !Swal.isLoading(),
                                                        }).then((result) => {
                                                            if (
                                                                result.isConfirmed
                                                            ) {
                                                                updateDetails({
                                                                    id: item.id,
                                                                    details:
                                                                        result.value,
                                                                });
                                                            }
                                                        })
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Add Details
                                                </div>
                                            )}
                                            {viewing && item.details !== "" && (
                                                <div className="text-muted fs-7">
                                                    Additional Details:{" "}
                                                    {item.details}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col className="text-center m-auto" xxl={2}>
                                    <span class="badge badge-light-primary">
                                        {item.category.toUpperCase()}
                                    </span>
                                </Col>
                                <Col className="text-center m-auto" xxl={1}>
                                    {!viewing ? (
                                        <Form.Control
                                            className="m-auto w-100"
                                            value={item.qty}
                                            onChange={(e) => {
                                                updateQty({
                                                    id: item.id,
                                                    qty:
                                                        Number(e.target.value) >
                                                        0
                                                            ? e.target.value
                                                            : 1,
                                                });
                                            }}
                                        />
                                    ) : (
                                        <span class="fw-bold ms-3">
                                            {item.qty}
                                        </span>
                                    )}
                                </Col>
                                <Col className="text-end m-auto" xxl={2}>
                                    {!viewing ? (
                                        <Form.Control
                                            className="m-auto w-75"
                                            value={item.price}
                                            onChange={(e) => {
                                                updatePrice({
                                                    id: item.id,
                                                    price:
                                                        Number(e.target.value) >
                                                        0
                                                            ? e.target.value
                                                            : item.price,
                                                });
                                            }}
                                        />
                                    ) : (
                                        <span class="fw-bold ms-3">
                                            {item.price}
                                        </span>
                                    )}
                                </Col>
                                <Col className="text-end m-auto" xxl={2}>
                                    <span class="fw-bold ms-3">
                                        ₱{item.total}.00
                                    </span>
                                </Col>
                                {!viewing && (
                                    <Col className="text-center m-auto" xxl={1}>
                                        <Button
                                            variant="outline-danger"
                                            onClick={() => {
                                                unSelectProductToList({
                                                    id: item.id,
                                                    qty: item.qty,
                                                    price: item.price,
                                                    total: item.total,
                                                });
                                            }}
                                        >
                                            <i class="fa-solid fa-trash"></i>
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </div>
                );
            });
        }

        setProductSelectedList(getDisplay);
    }, [productSelect]);

    useEffect(() => {
        let getDisplayModal = [];
        let itemIds = watch("groupItem.list").map((x) => x.id);

        if (productSelect.length > 0) {
            productSelect.map((item, index) => {
                if (item.category !== "GROUP ITEM") {
                    getDisplayModal.push(
                        <div
                            key={index}
                            className=" my-2"
                            data-kt-ecommerce-edit-order-filter="product"
                            data-kt-ecommerce-edit-order-id="product_1"
                        >
                            <Row
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: itemIds.includes(item.id)
                                        ? "#009ef740"
                                        : "white",
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        "#8080801a";
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        itemIds.includes(item.id)
                                            ? "#009ef740"
                                            : "white"; // #8080809e for selected group
                                }}
                                onClick={() => {
                                    selectProductToModalList(item);
                                }}
                            >
                                <Col xxl={8}>
                                    <div className="d-flex align-items-center">
                                        <a className="symbol symbol-50px">
                                            <span
                                                className="symbol-label"
                                                style={{
                                                    backgroundImage:
                                                        "url(assets/media//stock/ecommerce/1dfd.gif)",
                                                }}
                                                onError={replaceImage}
                                            />
                                        </a>
                                        <div className="ms-5">
                                            <a className="text-gray-800 text-hover-primary fs-5 fw-bold">
                                                {item.item}
                                            </a>
                                            <div className="fw-semibold fs-7">
                                                Brand: {item.brand || "---"}
                                            </div>
                                            <div className="fw-semibold fs-7">
                                                Type: {item.type || "---"}
                                            </div>
                                            <div className="text-muted fs-7">
                                                Part Number:{" "}
                                                {item.partNumber || "---"}
                                            </div>
                                            {item.details && !viewing && (
                                                <div className="text-muted fs-7">
                                                    Additional Details:{" "}
                                                    <div
                                                        className="btn btn-link btn-color-info btn-active-color-primary"
                                                        onClick={() =>
                                                            Swal.fire({
                                                                title: "Update the additional details",
                                                                input: "text",
                                                                showCancelButton: true,
                                                                confirmButtonText:
                                                                    "Add the details!",
                                                                showLoaderOnConfirm: true,
                                                                allowOutsideClick:
                                                                    () =>
                                                                        !Swal.isLoading(),
                                                            }).then(
                                                                (result) => {
                                                                    if (
                                                                        result.isConfirmed
                                                                    ) {
                                                                        updateDetails(
                                                                            {
                                                                                id: item.id,
                                                                                details:
                                                                                    result.value,
                                                                            }
                                                                        );
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {item.details}
                                                    </div>
                                                </div>
                                            )}
                                            {viewing && (
                                                <div className="text-muted fs-7">
                                                    Additional Details:{" "}
                                                    {item.details}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col className="text-center m-auto" xxl={4}>
                                    <span class="badge badge-light-primary">
                                        {item.category.toUpperCase()}
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    );
                }
            });
        }

        setProductSelectedListModal(getDisplayModal);
    }, [productSelect, watch("groupItem.list")]);

    useEffect(() => {
        let getProductList = [];

        if (isSuccess) {
            if (data.length > 0) {
                data.map((item, index) => {
                    getProductList.push(
                        <Row
                            key={index}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor =
                                    "#80808040";
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = "white";
                            }}
                            style={{
                                cursor: "pointer",
                                maxWidth: "115vh",
                                // minWidth: "90vh",
                                borderBottom:
                                    data.length - 1 !== index
                                        ? "1px dashed #8080806b"
                                        : "0px dashed #8080806b",
                                padding: "15px 0px",
                            }}
                            onClick={() =>
                                selectProductToList({
                                    id: item.item_id,
                                    stockId: item.stock_no,
                                    item: item.item_name,
                                    type: item.type,
                                    brand: item.brand,
                                    partNumber: item.article_no,
                                    category: item.category_name,
                                    qty: 1,
                                    price: item.stock_rate,
                                    total: item.stock_rate * 1,
                                    details: "",
                                    isGroup: false,
                                })
                            }
                        >
                            <Col xxl={5}>
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px">
                                        <span
                                            className="symbol-label"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/media//stock/ecommerce/1dfd.gif)",
                                            }}
                                            onError={replaceImage}
                                        />
                                    </div>
                                    <div className="ms-5">
                                        <div className="text-gray-800 text-hover-primary fs-5 fw-bold">
                                            {item.item_name}
                                        </div>
                                        <div className="fw-semibold fs-7">
                                            Brand: {item.brand || "---"}
                                        </div>
                                        <div className="fw-semibold fs-7">
                                            Type: {item.type || "---"}
                                        </div>
                                        <div className="text-muted fs-7">
                                            Part Number:{" "}
                                            {item.article_no || "---"}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="text-center m-auto" xxl={3}>
                                <span class="badge badge-light-primary">
                                    {item.category_name.toUpperCase()}
                                </span>
                            </Col>
                            <Col className="text-center m-auto" xxl={2}>
                                <span class="fw-bold ms-3">
                                    {item.stock_qty}
                                </span>
                            </Col>
                            <Col className="text-end m-auto" xxl={2}>
                                <span class="fw-bold ms-3">
                                    {" "}
                                    ₱ {item.stock_rate}
                                </span>
                            </Col>
                        </Row>
                    );
                });
            }
        }

        setProductList(getProductList);
    }, [data, isSuccess]);

    return (
        <div className="card card-flush py-4">
            <div className="card-header">
                <div className="card-title">
                    <h2>Select{viewing ? "ed" : ""} Products</h2>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="d-flex flex-column gap-10">
                    <div>
                        <label className="form-label">
                            {!viewing ? "Add a" : "Details of"} product to this
                            order
                        </label>
                        <div
                            className="row  border border-dashed rounded pt-3 pb-1 px-2 mb-5 mh-500px overflow-scroll"
                            id="kt_ecommerce_edit_order_selected_products"
                        >
                            <div
                                data-kt-ecommerce-edit-order-filter="product"
                                data-kt-ecommerce-edit-order-id="product_1"
                            >
                                <Row
                                    style={{
                                        borderBottom: "1px dashed #8080806b",
                                        paddingBottom: 10,
                                    }}
                                >
                                    <Col className="text-center m-auto" xxl={4}>
                                        <span class="fw-bold ms-3">
                                            Product
                                        </span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">
                                            Category
                                        </span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={1}>
                                        <span class="fw-bold ms-3">QTY</span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">Price</span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">Total</span>
                                    </Col>
                                    {!viewing && (
                                        <Col
                                            className="text-center m-auto"
                                            xxl={1}
                                        >
                                            <span class="fw-bold ms-3">
                                                Action
                                            </span>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                            {productSelectedList}

                            {productSelect.length === 0 && (
                                <span className="w-100 text-muted mt-5">
                                    {!viewing
                                        ? "Select one or more products from the list below by clicking the product."
                                        : "No product has been purchased."}
                                </span>
                            )}
                        </div>
                        <div className="fw-bold fs-4">
                            Total Cost: ₱
                            <span id="kt_ecommerce_edit_order_total_price">
                                {totalPrice}.00
                            </span>
                        </div>
                    </div>
                    {!viewing && (
                        <>
                            <div className="separator" />
                            <div className="row">
                                <div className="col-lg-8 col-12">
                                    <div className="d-flex justify-content-start flex-lg-row flex-column">
                                        <input
                                            type="text"
                                            data-kt-ecommerce-edit-order-filter="search"
                                            className="form-control form-control-solid w-lg-75 w-auto mb-2 mb-sm-0"
                                            placeholder="Search Products"
                                            autoFocus
                                            onChange={(e) => {
                                                if (
                                                    globalTimeout.current !==
                                                    null
                                                ) {
                                                    clearTimeout(
                                                        globalTimeout.current
                                                    );
                                                    setValue("isLoading", true);
                                                }
                                                globalTimeout.current =
                                                    setTimeout(() => {
                                                        setValue(
                                                            "search",
                                                            e.target.value
                                                        );
                                                        setValue(
                                                            "isLoading",
                                                            false
                                                        );
                                                    }, 1000);
                                            }}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            className="m-lg-auto"
                                            label="Search Tag"
                                            value={watch("isSearchTag")}
                                            onChange={(e) =>
                                                setValue(
                                                    "isSearchTag",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 d-flex justify-content-sm-end justify-content-start">
                                    <Button
                                        size="sm"
                                        variant="info"
                                        onClick={() => {
                                            setValue("modalShow", true);
                                        }}
                                    >
                                        Create Group Item
                                    </Button>
                                </div>
                            </div>

                            <Container className="mt-5 mh-500px overflow-scroll">
                                <Row
                                    style={{
                                        borderBottom: "1px dashed #8080806b",
                                        paddingBottom: 10,
                                    }}
                                >
                                    <Col className="text-center m-auto" xxl={5}>
                                        <span class="fw-bold ms-3">
                                            Product
                                        </span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={3}>
                                        <span class="fw-bold ms-3">
                                            Category
                                        </span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">QTY</span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">Price</span>
                                    </Col>
                                </Row>
                                {productList.length > 0 ? (
                                    productList
                                ) : watch("isLoading") ? (
                                    "loading..."
                                ) : (
                                    <>
                                        <br />
                                        <span className="w-100 text-muted">
                                            Search product to display item.
                                        </span>
                                    </>
                                )}
                            </Container>
                        </>
                    )}
                </div>
            </div>
            <GroupItem
                show={watch("modalShow")}
                submit={() => saveNewGroup()}
                onHide={() => setValue("modalShow", false)}
                productSelectedListModal={productSelectedListModal}
                setProductSelectedListModal={setProductSelectedListModal}
                register={register}
            />
        </div>
    );
};
