import React from "react";
import ReactDOM from "react-dom/client";

import { store } from "./redux/store";
import { Provider } from "react-redux";

import App from "./App";
import "./bundle";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
            <div className="d-flex flex-column flex-column-fluid">
                <App />
            </div>
        </div>
    </Provider>
);
