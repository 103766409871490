import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Table } from "../../../../../components/base";
import { useGetCustomerQuery } from "../../../../../redux/people/CustomerApi";

const TransactionHistory = () => {
    const { id } = useParams();
    const header = [
        {
            field: "sales_date",
            title: "Date",
            sort: true,
            width: "25%",
        },
        {
            field: "receipt_name",
            title: "Type",
            sort: true,
            textAlign: "center",
            width: "20%",
        },
        {
            field: "receipt_number",
            title: "Invoice No.",
            sort: true,
            textAlign: "center",
            width: "15%",
        },
        {
            field: "po_number",
            title: "PO No.",
            textAlign: "center",
            width: "15%",
        },
        {
            field: "status",
            title: "Status",
            sort: false,
            textAlign: "center",
            width: "10%",
        },
        {
            field: "sales_amount_total",
            title: "Total",
            textAlign: "end",
            width: "15%",
        },
    ];

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState(["sales_no", "DESC"]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    const [customerData, setCustomerData] = useState([]);

    const {
        data,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetCustomerQuery({
        endpoint: "get_customer_transaction_history",
        customer_id: id,
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        search,
        sort,
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (data) {
                setTotalPage(Math.ceil(data.total / activePagination));
                data.data.map((item) => {
                    getData.push({
                        id: item.sales_no,
                        sales_date: moment(item.sales_date).format("LL"),
                        receipt_name: item.receipt_name,
                        receipt_number: item.receipt_number,
                        po_number: item.po_number,
                        status: (
                            <Badge
                                bg={
                                    Number(item.paid) === 0 &&
                                    Number(item.balance) !== 0
                                        ? "danger"
                                        : Number(item.paid) !== 0 &&
                                          Number(item.balance) !== 0
                                        ? "warning"
                                        : Number(item.paid) === 0 &&
                                          Number(item.balance) === 0
                                        ? "info"
                                        : "primary"
                                }
                            >
                                {Number(item.paid) === 0 &&
                                Number(item.balance) !== 0
                                    ? "No Payment"
                                    : Number(item.paid) !== 0 &&
                                      Number(item.balance) !== 0
                                    ? "Pending"
                                    : Number(item.paid) === 0 &&
                                      Number(item.balance) === 0
                                    ? "Collection Receipt"
                                    : "Paid"}
                            </Badge>
                        ),
                        sales_amount_total: `₱ ${item.sales_amount_total}`,
                    });
                });
            }
        }
        setIsLoading(fetchLoading);
        setCustomerData(getData);
    }, [isSuccess, data, fetchLoading]);

    return (
        <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-0">
                <div className="card-title">
                    <h2>Transaction History</h2>
                </div>
                <div class="card-toolbar">
                    <Form.Control
                        className="form-control form-control-solid"
                        size="sm"
                        placeholder="Search Transaction"
                        onChange={(e) => {
                            setIsLoading(true);
                            setTimeout(() => {
                                setSearch(e.target.value);
                                setActive(1);
                                setIsLoading(false);
                            }, 1000);
                        }}
                    />
                </div>
            </div>
            <div className="card-body pt-0 pb-5">
                <Table
                    head={header}
                    body={customerData}
                    handleClick="salesDetail"
                    isLoading={isLoading}
                    active={active}
                    setActive={setActive}
                    totalPage={totalPage}
                    activePagination={activePagination}
                    setActivePagination={setActivePagination}
                    sort={sort}
                    setSort={setSort}
                />
            </div>
        </div>
    );
};

export default TransactionHistory;
