import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";

export const Sidebar = ({ status, setStatus, children }) => {
    const [isPublished, setPublished] = useState("");
    const [userData, setUserData] = useState("");

    useEffect(() => {
        setPublished(status);
    }, [status]);

    useEffect(() => {
        setStatus(isPublished);
    }, [isPublished]);

    useEffect(() => {
        const data = Cookies.get("userData");
        if (data) {
            let userInfo = JSON.parse(data);
            setUserData(userInfo);
        }
    }, [Cookies]);

    return (
        <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
            {/* <div className="card card-flush py-4">
                <div className="card-header">
                    <div className="card-title">
                        <h2>Thumbnail</h2>
                    </div>
                </div>
                <div className="card-body text-center pt-0">
                    <div
                        className="image-input image-input-empty image-input-outline image-input-placeholder mb-3"
                        data-kt-image-input="true"
                    >
                        <div
                            className="image-input-wrapper w-150px h-150px"
                            style={{
                                backgroundImage:
                                    "url(assets/media//stock/ecommerce/123.gif)",
                            }}
                        />
                        <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="change"
                            data-bs-toggle="tooltip"
                            title="Change avatar"
                        >
                            <i className="bi bi-pencil-fill fs-7" />
                            <input
                                type="file"
                                name="avatar"
                                accept=".png, .jpg, .jpeg"
                            />
                            <input type="hidden" name="avatar_remove" />
                        </label>
                        <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="cancel"
                            data-bs-toggle="tooltip"
                            title="Cancel avatar"
                        >
                            <i className="bi bi-x fs-2" />
                        </span>
                        <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="remove"
                            data-bs-toggle="tooltip"
                            title="Remove avatar"
                        >
                            <i className="bi bi-x fs-2" />
                        </span>
                    </div>
                    <div className="text-muted fs-7">
                        Set the category thumbnail image. Only *.png, *.jpg and
                        *.jpeg image files are accepted
                    </div>
                </div>
            </div> */}
            {Number(userData.is_superadmin) === 1 && (
                <Card className="card-flush py-4">
                    <Card.Header>
                        <div className="card-title">
                            <h2>Status</h2>
                        </div>
                        <div className="card-toolbar">
                            <div
                                className={`rounded-circle bg-${
                                    isPublished === "1" ? "success" : "danger"
                                } w-15px h-15px`}
                                id="kt_ecommerce_add_category_status"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        <Form.Select
                            className="form-select mb-2"
                            placeholder="Select an option"
                            value={isPublished}
                            onChange={(e) => setPublished(e.target.value)}
                        >
                            <option value="1">Published</option>
                            <option value="0">Unpublished</option>
                        </Form.Select>
                        <div className="text-muted fs-7">
                            Set the category status.
                        </div>
                    </Card.Body>
                </Card>
            )}
            {children}
        </div>
    );
};
