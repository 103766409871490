import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Alert, Button, Form, Row, Spinner } from "react-bootstrap";

import { useUserActionMutation } from "../../../redux/user/UserApi";

export const ShowLogin = () => {
    const [validated, setValidated] = useState(false);
    const [isMismatch, setIsMismatch] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [userValidation, { data, isSuccess, isError }] =
        useUserActionMutation();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);

        if (form.checkValidity()) {
            setLoading(true);
            userValidation({
                username,
                password,
                endpoint: "check_validation",
                token: true,
            });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            if (data.status === "success") {
                Cookies.set("token", data.token);
                window.location.replace("/");
            } else {
                setIsMismatch(true);
            }
        } else if (isError) {
            alert("Something went wrong");
        }
        setLoading(false);
    }, [isSuccess, isError, data]);

    return (
        <div className="d-flex flex-center w-lg-50 p-10">
            <div className="card rounded-3 w-md-550px">
                <div className="card-body p-10 p-lg-20">
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <div className="text-center mb-11">
                            <h1 className="text-dark fw-bolder mb-3">
                                Sign In
                            </h1>
                            <div className="text-gray-500 fw-semibold fs-6">
                                This login page will be used one time
                            </div>
                        </div>

                        {isMismatch && (
                            <Alert variant="danger">
                                Username or Password Mismatch
                            </Alert>
                        )}
                        <Row className="mb-8">
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Username"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Username is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group controlId="validationCustom05">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    required
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    Password is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <div className="d-grid mb-10">
                            <Button type="submit" disabled={isLoading}>
                                {" "}
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{" "}
                                        Loading...
                                    </>
                                ) : (
                                    "Sign In"
                                )}
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};
