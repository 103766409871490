export const TotalPurchase = ({ purchase }) => {
    return (
        <div className="col">
            <div className="card pt-4 h-md-100 mb-6 mb-md-0">
                <div className="card-header border-0">
                    <div className="card-title">
                        <h2 className="fw-bold">Total Purchase</h2>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="fw-bold fs-2">
                        <div className="d-flex">
                            <span className="svg-icon svg-icon-info svg-icon-2x">
                                <i class="fa-solid fa-circle-exclamation text-primary"></i>
                            </span>
                            <div className="ms-2">{purchase}</div>
                        </div>
                        <div className="fs-7 fw-normal text-muted">
                            Total purchase of the year.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
