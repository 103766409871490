import moment from "moment/moment";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Container, Toolbar } from "../../../components/layout";
import { useGetCustomerQuery } from "../../../redux/people/CustomerApi";
import Sidebar from "../components/details/Sidebar";
import TabContent from "./TabContent";

export const CustomerDetails = () => {
    const { id } = useParams();
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
        {
            label: "Customer",
            link: "/customer",
        },
    ];

    const [name, setName] = useState(null);
    const [phoneNo, setPhoneNo] = useState(null);
    const [address, setAddress] = useState(null);
    const [latestPurchase, setLatestPurchase] = useState("-No Data Available-");
    const [latestPurchaseDate, setLatestPurchaseDate] = useState(
        "-No Purchase Transaction-"
    );

    const [balance, setBalance] = useState("0.00");

    const { data, isSuccess } = useGetCustomerQuery({
        endpoint: "get_customer_details",
        customer_id: id,
        token: true,
    });

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setBalance(data.total_balance || balance);

                if (data.customer_details) {
                    setName(data.customer_details.customer_name);
                    setPhoneNo(data.customer_details.phone_no);
                    setAddress(data.customer_details.address);
                }

                if (data.latest_purchase) {
                    setLatestPurchase(
                        data.latest_purchase.receipt_number || latestPurchase
                    );
                    setLatestPurchaseDate(
                        `Last purchase was on ${moment(
                            data.latest_purchase.sales_date
                        ).format("LL")}` || latestPurchaseDate
                    );
                }
            }
        }
    }, [isSuccess, data]);

    return (
        <>
            <Toolbar label="Customer Details" breadcrumb={breadcrumb} />
            <Container>
                <div className="d-flex flex-column flex-xl-row">
                    <Sidebar
                        name={name}
                        phoneNo={phoneNo}
                        address={address}
                        latestPurchase={latestPurchase}
                    />
                    <TabContent
                        balance={balance}
                        latestPurchase={latestPurchaseDate}
                        name={name}
                        phoneNo={phoneNo}
                        address={address}
                    />
                </div>
            </Container>
        </>
    );
};
