import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apilink, prepareApiHeader } from "../../pages/General";

export const customerSlice = createApi({
    reducerPath: "customerApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${apilink}customerApi/graphql`,
        prepareHeaders: prepareApiHeader,
    }),
    tagTypes: ["customer"],
    endpoints: (builder) => ({
        getCustomer: builder.query({
            query: (body) => ({
                url: "",
                method: "POST",
                body,
            }),
            providesTags: ["customer"],
        }),
        customerAction: builder.mutation({
            query: (forms) => ({
                url: "",
                method: "POST",
                body: forms,
            }),
            invalidatesTags: ["customer"],
        }),
    }),
});

export const { useGetCustomerQuery, useCustomerActionMutation } = customerSlice;
