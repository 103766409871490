import { Toolbar, Container } from "../../components/layout";

export const Dashboard = () => {
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
    ];

    return (
        <>
            <style
                dangerouslySetInnerHTML={{
                    __html: "body { background-image: url('assets/media/auth/bg9.jpg'); } [data-theme=\"dark\"] body { background-image: url('assets/media/auth/bg9-dark.jpg'); }",
                }}
            />

            <div className="d-flex flex-column flex-center flex-column-fluid">
                <div className="d-flex flex-column flex-center text-center p-10">
                    <div className="card card-flush w-lg-650px py-5">
                        <div className="card-body py-15 py-lg-20">
                            <div className="mb-13">
                                <a href="../../demo1/dist/index.html" className>
                                    <img
                                        alt="Logo"
                                        src="assets/media/logos/custom-2.svg"
                                        className="h-40px"
                                    />
                                </a>
                            </div>
                            <h1 className="fw-bolder text-gray-900 mb-7">
                                We're Launching Soon
                            </h1>
                            <div className="fw-semibold fs-6 text-gray-500 mb-7">
                                Stay tune!
                            </div>
                            <div className="mb-n5">
                                <img
                                    src="assets/media/auth/chart-graph.png"
                                    className="mw-100 mh-300px theme-light-show"
                                    alt
                                />
                                <img
                                    src="assets/media/auth/chart-graph-dark.png"
                                    className="mw-100 mh-300px theme-dark-show"
                                    alt
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
