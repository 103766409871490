import Cookies from "js-cookie";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Appbar from "./components/general/Appbar";
import Sidebar from "./components/general/Sidebar";
import "react-toastify/ReactToastify.min.css";

import { _hook } from "./pages/Login/components/_hook";

export const PrivateRoute = () => {
    const { data, isSuccess } = _hook();

    useEffect(() => {
        if (isSuccess) {
            if (data != "Show Dashboard!") {
                window.location.replace("/login");
            }
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (!Cookies.get("token")) {
            window.location.replace("/login");
        }
    }, [Cookies]);

    return (
        <div
            className="app-page flex-column flex-column-fluid"
            id="kt_app_page"
        >
            <ToastContainer
                position="top-right"
                autoClose={5000}
                limit={3}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
            />

            <Appbar />
            <div
                className="app-wrapper flex-column flex-row-fluid"
                id="kt_app_wrapper"
            >
                <Sidebar />
                <div
                    className="app-main flex-column flex-row-fluid"
                    id="kt_app_main"
                >
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
