import { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useGetProductQuery } from "../../../redux/catalog/ProductApi";
import { replaceImage } from "../../General";

export const SelectProduct = ({
    viewing,
    productSelect,
    setProductSelect,
    totalPrice,
}) => {
    const globalTimeout = useRef;

    const [search, setSearch] = useState("");
    const [selectedId, setSelectedId] = useState([]);

    const [productList, setProductList] = useState([]);
    const [productSelectedList, setProductSelectedList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchTag, setIsSearchTag] = useState(false);

    const { data, isSuccess } = useGetProductQuery(
        {
            endpoint: "get_product_filter",
            token: true,
            search,
            isSearchTag: isSearchTag,
            removeFromReturn: selectedId.toString(),
        },
        { skip: search === "" }
    );

    const selectProductToList = (data) => {
        setProductSelect((oldArray) => [...oldArray, data]);
        setSelectedId((oldArray) => [...oldArray, data.id]);
    };

    const unSelectProductToList = (data) => {
        setProductSelect((oldArray) =>
            oldArray.filter((item) => item.id !== data.id)
        );
        setSelectedId((oldArray) =>
            oldArray.filter((item) => item !== data.id)
        );
    };

    const updateQty = (data) => {
        setProductSelect((oldArray) => {
            return oldArray.map((item) => {
                return item.id === data.id
                    ? { ...item, qty: data.qty, total: data.qty * item.price }
                    : item;
            });
        });
    };

    const updatePrice = (data) => {
        setProductSelect((oldArray) => {
            return oldArray.map((item) => {
                return item.id === data.id
                    ? {
                          ...item,
                          price: data.price,
                          total: item.qty * data.price,
                      }
                    : item;
            });
        });
    };

    useEffect(() => {
        let getDisplay = [];

        if (productSelect.length > 0) {
            productSelect.map((item, index) => {
                getDisplay.push(
                    <div
                        key={index}
                        className=" my-2"
                        data-kt-ecommerce-edit-order-filter="product"
                        data-kt-ecommerce-edit-order-id="product_1"
                    >
                        <Row>
                            <Col xxl={4}>
                                <div
                                    className="d-flex align-items-center"
                                    data-kt-ecommerce-edit-order-filter="product"
                                    data-kt-ecommerce-edit-order-id="product_1"
                                >
                                    <a className="symbol symbol-50px">
                                        <span
                                            className="symbol-label"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/media//stock/ecommerce/1dfd.gif)",
                                            }}
                                            onError={replaceImage}
                                        />
                                    </a>
                                    <div className="ms-5">
                                        <a className="text-gray-800 text-hover-primary fs-5 fw-bold">
                                            {item.item}
                                        </a>
                                        <div className="fw-semibold fs-7">
                                            Brand: {item.brand || "---"}
                                        </div>
                                        <div className="fw-semibold fs-7">
                                            Type: {item.type || "---"}
                                        </div>
                                        <div className="text-muted fs-7">
                                            Part Number:{" "}
                                            {item.partNumber || "---"}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="text-center m-auto" xxl={2}>
                                <span class="badge badge-light-primary">
                                    {item.category.toUpperCase()}
                                </span>
                            </Col>
                            <Col className="text-center m-auto" xxl={1}>
                                {!viewing ? (
                                    <Form.Control
                                        className="m-auto w-100"
                                        value={item.qty}
                                        onChange={(e) => {
                                            updateQty({
                                                id: item.id,
                                                qty:
                                                    Number(e.target.value) > 0
                                                        ? e.target.value
                                                        : 1,
                                            });
                                        }}
                                    />
                                ) : (
                                    <span class="fw-bold ms-3">{item.qty}</span>
                                )}
                            </Col>
                            <Col className="text-end m-auto" xxl={2}>
                                {!viewing ? (
                                    <Form.Control
                                        className="m-auto w-75"
                                        value={item.price}
                                        onChange={(e) => {
                                            updatePrice({
                                                id: item.id,
                                                price:
                                                    Number(e.target.value) > 0
                                                        ? e.target.value
                                                        : item.price,
                                            });
                                        }}
                                    />
                                ) : (
                                    <span class="fw-bold ms-3">
                                        {item.price}
                                    </span>
                                )}
                            </Col>
                            <Col className="text-end m-auto" xxl={2}>
                                <span class="fw-bold ms-3">
                                    ₱{item.total}.00
                                </span>
                            </Col>
                            {!viewing && (
                                <Col className="text-center m-auto" xxl={1}>
                                    <Button
                                        variant="outline-danger"
                                        onClick={() => {
                                            unSelectProductToList({
                                                id: item.id,
                                                qty: item.qty,
                                                price: item.price,
                                                total: item.total,
                                            });
                                        }}
                                    >
                                        <i class="fa-solid fa-trash"></i>
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </div>
                );
            });
        }

        setProductSelectedList(getDisplay);
    }, [productSelect]);

    useEffect(() => {
        let getProductList = [];

        if (isSuccess) {
            if (data.length > 0) {
                data.map((item, index) => {
                    getProductList.push(
                        <Row
                            key={index}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor =
                                    "#80808040";
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = "white";
                            }}
                            style={{
                                cursor: "pointer",
                                maxWidth: "115vh",
                                minWidth: "90vh",
                                borderBottom:
                                    data.length - 1 !== index
                                        ? "1px dashed #8080806b"
                                        : "0px dashed #8080806b",
                                padding: "15px 0px",
                            }}
                            onClick={() =>
                                selectProductToList({
                                    id: item.item_id,
                                    item: item.item_name,
                                    type: item.type,
                                    brand: item.brand,
                                    partNumber: item.article_no,
                                    category: item.category_name,
                                    qty: 1,
                                    price: item.purchase_rate,
                                    total: item.purchase_rate * 1,
                                })
                            }
                        >
                            <Col xxl={5}>
                                <div
                                    className="d-flex align-items-center"
                                    data-kt-ecommerce-edit-order-filter="product"
                                    data-kt-ecommerce-edit-order-id="product_1"
                                >
                                    <a
                                        href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html"
                                        className="symbol symbol-50px"
                                    >
                                        <span
                                            className="symbol-label"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/media//stock/ecommerce/1dfd.gif)",
                                            }}
                                            onError={replaceImage}
                                        />
                                    </a>
                                    <div className="ms-5">
                                        <a
                                            href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html"
                                            className="text-gray-800 text-hover-primary fs-5 fw-bold"
                                        >
                                            {item.item_name}
                                        </a>
                                        <div className="fw-semibold fs-7">
                                            Brand: {item.brand || "---"}
                                        </div>
                                        <div className="fw-semibold fs-7">
                                            Type: {item.type || "---"}
                                        </div>
                                        <div className="text-muted fs-7">
                                            Part Number:{" "}
                                            {item.article_no || "---"}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="text-center m-auto" xxl={3}>
                                <span class="badge badge-light-primary">
                                    {item.category_name.toUpperCase()}
                                </span>
                            </Col>
                            <Col className="text-center m-auto" xxl={2}>
                                <span class="fw-bold ms-3">
                                    {item.stock_qty}
                                </span>
                            </Col>
                            <Col className="text-end m-auto" xxl={2}>
                                <span class="fw-bold ms-3">
                                    {" "}
                                    ₱ {item.purchase_rate}.00
                                </span>
                            </Col>
                        </Row>
                    );
                });
            }
        }

        setProductList(getProductList);
    }, [data, isSuccess]);

    return (
        <div className="card card-flush py-4">
            <div className="card-header">
                <div className="card-title">
                    <h2>Select{viewing ? "ed" : ""} Products</h2>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="d-flex flex-column gap-10">
                    <div>
                        <label className="form-label">
                            {!viewing ? "Add a" : "Details of"} product to this
                            order
                        </label>
                        <div
                            className="row  border border-dashed rounded pt-3 pb-1 px-2 mb-5 mh-500px overflow-scroll"
                            id="kt_ecommerce_edit_order_selected_products"
                        >
                            <div
                                data-kt-ecommerce-edit-order-filter="product"
                                data-kt-ecommerce-edit-order-id="product_1"
                            >
                                <Row
                                    style={{
                                        borderBottom: "1px dashed #8080806b",
                                        paddingBottom: 10,
                                    }}
                                >
                                    <Col className="text-center m-auto" xxl={4}>
                                        <span class="fw-bold ms-3">
                                            Product
                                        </span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">
                                            Category
                                        </span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={1}>
                                        <span class="fw-bold ms-3">QTY</span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">Price</span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">Total</span>
                                    </Col>
                                    {!viewing && (
                                        <Col
                                            className="text-center m-auto"
                                            xxl={1}
                                        >
                                            <span class="fw-bold ms-3">
                                                Action
                                            </span>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                            {productSelectedList}
                            {productSelect.length === 0 && (
                                <span className="w-100 text-muted mt-5">
                                    {!viewing
                                        ? "Select one or more products from the list below by clicking the product."
                                        : "No product has been purchased."}
                                </span>
                            )}
                        </div>
                        <div className="fw-bold fs-4">
                            Total Cost: ₱
                            <span id="kt_ecommerce_edit_order_total_price">
                                {totalPrice}.00
                            </span>
                        </div>
                    </div>
                    {!viewing && (
                        <>
                            <div className="separator" />
                            <div className="d-flex align-items-center position-relative mb-n7">
                                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x="17.0365"
                                            y="15.1223"
                                            width="8.15546"
                                            height={2}
                                            rx={1}
                                            transform="rotate(45 17.0365 15.1223)"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>
                                <input
                                    type="text"
                                    data-kt-ecommerce-edit-order-filter="search"
                                    className="form-control form-control-solid w-100 w-lg-50 ps-14"
                                    placeholder="Search Products"
                                    autoFocus
                                    onChange={(e) => {
                                        if (globalTimeout.current !== null) {
                                            clearTimeout(globalTimeout.current);
                                            setIsLoading(true);
                                        }
                                        globalTimeout.current = setTimeout(
                                            () => {
                                                setSearch(e.target.value);
                                                setIsLoading(false);
                                            },
                                            1000
                                        );
                                    }}
                                />
                                <Form.Check
                                    type="checkbox"
                                    className="m-lg-auto"
                                    label="Search Tag"
                                    value={isSearchTag}
                                    onChange={(e) =>
                                        setIsSearchTag(e.target.checked)
                                    }
                                />
                            </div>

                            <Container className="mt-5 mh-500px overflow-scroll">
                                <Row
                                    style={{
                                        borderBottom: "1px dashed #8080806b",
                                        paddingBottom: 10,
                                    }}
                                >
                                    <Col className="text-center m-auto" xxl={5}>
                                        <span class="fw-bold ms-3">
                                            Product
                                        </span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={3}>
                                        <span class="fw-bold ms-3">
                                            Category
                                        </span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">QTY</span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={2}>
                                        <span class="fw-bold ms-3">Price</span>
                                    </Col>
                                </Row>
                                {productList.length > 0 ? (
                                    productList
                                ) : isLoading ? (
                                    "loading..."
                                ) : (
                                    <>
                                        <br />
                                        <span className="w-100 text-muted">
                                            Search product to display item.
                                        </span>
                                    </>
                                )}
                            </Container>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
