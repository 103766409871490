import { configureStore } from "@reduxjs/toolkit";
import { customerSlice } from "./people/CustomerApi";
import { purchaserSlice } from "./people/PurchaserApi";
import { categorySlice } from "./catalog/CategoryApi";
import { productSlice } from "./catalog/ProductApi";
import { unitSlice } from "./catalog/UnitApi";
import { purchaseSlice } from "./transaction/PurchaseApi";
import { salesSlice } from "./transaction/SalesApi";
import { userSlice } from "./user/UserApi";
export const store = configureStore({
    reducer: {
        [customerSlice.reducerPath]: customerSlice.reducer,
        [purchaserSlice.reducerPath]: purchaserSlice.reducer,
        [categorySlice.reducerPath]: categorySlice.reducer,
        [productSlice.reducerPath]: productSlice.reducer,
        [purchaseSlice.reducerPath]: purchaseSlice.reducer,
        [salesSlice.reducerPath]: salesSlice.reducer,
        [userSlice.reducerPath]: userSlice.reducer,
        [unitSlice.reducerPath]: unitSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            [customerSlice.middleware],
            [purchaserSlice.middleware],
            [categorySlice.middleware],
            [productSlice.middleware],
            [purchaseSlice.middleware],
            [salesSlice.middleware],
            [userSlice.middleware],
            [unitSlice.middleware]
        ),
});
