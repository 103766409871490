import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardBody, CardHeader, Table } from "../../components/base";
import { Container, Toolbar } from "../../components/layout";
import { Button } from "react-bootstrap";
import { useGetPurchaseQuery } from "../../redux/transaction/PurchaseApi";
import moment from "moment/moment";

export const Purchase = () => {
    const navigate = useNavigate();
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
    ];

    const header = [
        {
            field: "pur_no",
            title: "Purchase No.",
            sort: true,
            width: "15%",
            textAlign: "center",
        },
        {
            field: "company_name",
            title: "Supplier Name",
            sort: true,
            width: "40%",
        },
        {
            field: "purchase_date",
            title: "Date",
            sort: true,
            width: "20%",
            textAlign: "center",
        },
        {
            field: "due_amount",
            title: "Purchase Status",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "grand_total",
            title: "Total",
            sort: true,
            width: "15%",
            textAlign: "end",
        },
    ];

    const [search, setSearch] = useState("");
    const [sort, setSort] = useState(["purchase_date", "DESC"]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPagination, setTotalPagination] = useState(0);
    const [data, setData] = useState([]);

    const {
        data: categoryData,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetPurchaseQuery({
        endpoint: "get_purchase",
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        search,
        sort,
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (categoryData) {
                setTotalPagination(
                    Math.ceil(categoryData.total / activePagination)
                );
                categoryData.data.map((item) => {
                    getData.push({
                        id: item.purchase_no,
                        pur_no: item.pur_no,
                        company_name: item.company_name,
                        purchase_date: moment(item.purchase_date).format("LL"),
                        due_amount: (
                            <div
                                class={`badge badge-light-${
                                    item.due_amount == 0 ? "primary" : "warning"
                                }`}
                            >
                                {item.due_amount == 0 ? "Confirmed" : "Pending"}
                            </div>
                        ),
                        grand_total: item.grand_total,
                    });
                });
            }
        }
        setIsLoading(fetchLoading);
        setData(getData);
    }, [categoryData, isSuccess, fetchLoading]);

    return (
        <>
            <Toolbar label="Purchase" breadcrumb={breadcrumb} />
            <Container>
                <Card>
                    <CardHeader
                        sx="border-0 pt-6"
                        action={
                            <Button onClick={() => navigate("/purchaseAdd")}>
                                Add Purchase
                            </Button>
                        }
                    >
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input
                                type="text"
                                data-kt-customer-table-filter="search"
                                className="form-control form-control-solid w-250px ps-15"
                                placeholder="Search Purchase"
                                onChange={(e) => {
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        setSearch(e.target.value);
                                        setActive(1);
                                        setIsLoading(false);
                                    }, 1000);
                                }}
                            />
                        </div>
                    </CardHeader>
                    <CardBody sx="pt-0">
                        <Table
                            head={header}
                            body={data}
                            handleClick={"purchaseDetails"}
                            isLoading={isLoading}
                            active={active}
                            setActive={setActive}
                            totalPage={totalPagination}
                            activePagination={activePagination}
                            setActivePagination={setActivePagination}
                            sort={sort}
                            setSort={setSort}
                        />
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};
