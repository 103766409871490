import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apilink, prepareApiHeader } from "../../pages/General";

export const categorySlice = createApi({
    reducerPath: "categoryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${apilink}categoryApi/graphql`,
        prepareHeaders: prepareApiHeader,
    }),
    tagTypes: ["category"],
    endpoints: (builder) => ({
        getCategory: builder.query({
            query: (body) => ({
                url: "",
                method: "POST",
                body,
            }),
            providesTags: ["category"],
        }),
        categoryAction: builder.mutation({
            query: (forms) => ({
                url: "",
                method: "POST",
                body: forms,
            }),
            invalidatesTags: ["category"],
        }),
    }),
});

export const { useGetCategoryQuery, useCategoryActionMutation } = categorySlice;
