import { _hook } from "./components/_hook";
import { ShowLogin } from "./components/ShowLogin";

export function Login() {
    const { setBackgroundImage } = _hook();

    return (
        <div
            className="d-flex flex-column flex-column-fluid flex-lg-row"
            style={{
                backgroundImage: setBackgroundImage,
                height: "100vh",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            }}
        >
            <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
                <div className="d-flex flex-column">
                    <div className="mb-7">
                        <img alt="Logo" src="images/invesystem.webp" />
                    </div>
                    <h2 className="text-white fw-normal m-auto">
                        Jeanica Hydraulic Trading
                    </h2>
                </div>
            </div>
            <ShowLogin />
        </div>
    );
}
