import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Container, Toolbar } from "../../../../components/layout";
import { Sidebar } from "../../components/Sidebar";
import {
    useGetProductQuery,
    useProductActionMutation,
} from "../../../../redux/catalog/ProductApi";
import { SidebarProductDetails } from "../../components/SidebarProductDetails";
import { toast } from "react-toastify";

function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
}

export const EditProduct = () => {
    const { id } = useParams();
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
        {
            label: "Product",
            link: "/product",
        },
    ];

    const [isLoading, setLoading] = useState(false);
    const [itemName, setItemName] = useState("");
    const [category, setCategory] = useState("");
    const [brand, setBrand] = useState("");
    const [type, setType] = useState("");
    const [partNumber, setPartNumber] = useState("");
    const [purchaseRate, setPurchaseRate] = useState(0.0);
    const [stockRate, setStockRate] = useState(0.0);
    const [status, setStatus] = useState("");
    const [unit, setUnit] = useState("");
    const [searchTag, setSearchTag] = useState("");

    const { data, isSuccess } = useGetProductQuery({
        endpoint: "get_product_details",
        item_id: id,
        token: true,
    });

    const [updateDetails, { isSuccess: updateSuccess, isError: updateError }] =
        useProductActionMutation();

    useEffect(() => {
        if (isLoading) {
            simulateNetworkRequest().then(() => {
                updateDetails({
                    item_id: id,
                    category_id: category["value"],
                    item_name: itemName,
                    brand,
                    type,
                    unit: unit["value"],
                    article_no: partNumber,
                    flag: status,
                    purchase_rate: purchaseRate,
                    stock_rate: stockRate,
                    searchTag,
                    endpoint: "update_product_details",
                    token: true,
                });
                setLoading(false);
            });
        }
    }, [isLoading]);

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setCategory({
                    value: data.category_id,
                    label: data.category_name,
                });
                setUnit({ value: data.unit_id, label: data.unit_name });
                setItemName(data.item_name);
                setBrand(data.brand);
                setType(data.type);
                setPartNumber(data.article_no);
                setPurchaseRate(data.purchase_rate);
                setStockRate(data.stock_rate);
                setStatus(data.flag);
                setSearchTag(data.searchTag);
            }
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (updateSuccess) {
            toast.success("Product details has been saved");
        } else if (updateError) {
            toast.error("Failed to saved detail! Please contact the Admin");
        }
    }, [updateSuccess, updateError]);

    const handleClick = () => setLoading(true);

    return (
        <>
            <Toolbar label="Edit Product" breadcrumb={breadcrumb} />
            <Container>
                <div className="d-flex flex-column flex-lg-row">
                    <Sidebar status={status} setStatus={setStatus}>
                        <SidebarProductDetails
                            category={category}
                            setCategory={setCategory}
                            unit={unit}
                            setUnit={setUnit}
                            searchTag={searchTag}
                            setSearchTag={setSearchTag}
                        />
                    </Sidebar>

                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <Card className="card-flush py-4">
                            <Card.Header>
                                <div className="card-title">
                                    <h2>General</h2>
                                </div>
                            </Card.Header>
                            <Card.Body className="pt-0">
                                <Form>
                                    <Form.Group className="mb-10 fv-row">
                                        <Form.Label className="required form-label">
                                            Product Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Product name"
                                            value={itemName}
                                            onChange={(e) =>
                                                setItemName(e.target.value)
                                            }
                                        />
                                        <div className="text-muted fs-7">
                                            A category name is required and
                                            recommended to be unique.
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-10 fv-row">
                                        <Row>
                                            <Col>
                                                <Form.Label className="form-label">
                                                    Brand
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control mb-2"
                                                    placeholder="Brand"
                                                    value={brand}
                                                    onChange={(e) =>
                                                        setBrand(e.target.value)
                                                    }
                                                />
                                                <div className="text-muted fs-7">
                                                    Set the brand of the product
                                                    but not required.
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Label className="form-label">
                                                    Type
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control mb-2"
                                                    placeholder="Type"
                                                    value={type}
                                                    onChange={(e) =>
                                                        setType(e.target.value)
                                                    }
                                                />
                                                <div className="text-muted fs-7">
                                                    Set the type of the product
                                                    but not required.
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Label className="form-label">
                                                    Part Number
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control mb-2"
                                                    placeholder="Part Number"
                                                    value={partNumber}
                                                    onChange={(e) =>
                                                        setPartNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <div className="text-muted fs-7">
                                                    Set the part number of the
                                                    product but not required.
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Card className="card-flush py-4">
                            <Card.Header>
                                <div className="card-title">
                                    <h2>Pricing</h2>
                                </div>
                            </Card.Header>
                            <Card.Body className="pt-0">
                                <Form>
                                    <Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Label className="form-label required">
                                                    Purchase Rate
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control mb-2"
                                                    placeholder="Brand"
                                                    value={purchaseRate}
                                                    onChange={(e) =>
                                                        setPurchaseRate(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <div className="text-muted fs-7">
                                                    Set the purchase rate of the
                                                    product.
                                                </div>
                                            </Col>
                                            <Col>
                                                <Form.Label className="form-label required">
                                                    Stock Rate
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control mb-2"
                                                    placeholder="Type"
                                                    value={stockRate}
                                                    onChange={(e) =>
                                                        setStockRate(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <div className="text-muted fs-7">
                                                    Set the selling price of the
                                                    product
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                        <div className="d-flex justify-content-end">
                            <Button
                                variant="primary"
                                disabled={isLoading}
                                onClick={!isLoading ? handleClick : null}
                            >
                                {isLoading ? (
                                    <>
                                        Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </>
                                ) : (
                                    "Save Changes"
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};
