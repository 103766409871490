export const CardHeader = (props) => {
    return (
        <>
            {props.collapse ? (
                <div
                    className={`card-header collapsible cursor-pointer rotate ${
                        props.sx !== undefined ? props.sx : ""
                    }`}
                    data-bs-toggle="collapse"
                    data-bs-target={`#${props.id}`}
                    {...props}
                >
                    <h3 className="card-title">{props.children}</h3>
                    <div className="card-toolbar rotate-180">
                        {props.action}
                    </div>
                </div>
            ) : (
                <div
                    className={`card-header  ${
                        props.stretch ? "card-header-stretch" : ""
                    } ${props.ribbon ? "ribbon" : ""} ${
                        props.ribbon && props.ribbon.direction
                            ? `ribbon-${props.ribbon.direction} ${
                                  props.ribbon.direction === "start"
                                      ? "justify-content-end "
                                      : ""
                              }`
                            : ""
                    } ${
                        props.ribbon && props.ribbon.vertical
                            ? "ribbon-vertical"
                            : ""
                    } ${
                        props.ribbon && props.ribbon.clip ? "ribbon-clip" : ""
                    } ${props.sx !== undefined ? props.sx : ""}`}
                    {...props}
                >
                    <div className="card-title">{props.children}</div>
                    {props.ribbon && (
                        <div
                            className={`ribbon-label bg-${
                                props.ribbon && props.ribbon.color
                                    ? props.ribbon.color
                                    : "primary"
                            } ${
                                props.ribbon && props.ribbon.sx !== undefined
                                    ? props.ribbon.sx
                                    : ""
                            }`}
                        >
                            {props.ribbon && props.ribbon.label}
                            {props.ribbon && props.ribbon.clip && (
                                <span
                                    class={`ribbon-inner ${
                                        props.ribbon && props.ribbon.color
                                            ? `bg-${props.ribbon.color}`
                                            : "bg-primary"
                                    }`}
                                ></span>
                            )}
                        </div>
                    )}
                    {props.action && (
                        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                            {props.action}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
