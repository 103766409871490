import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import { useCustomerActionMutation } from "../../../redux/people/CustomerApi";
import ActiveMember from "../components/details/customerComponents/ActiveMember";
import TotalBalance from "../components/details/customerComponents/TotalBalance";
import TransactionHistory from "../components/details/customerComponents/TransactionHistory";
import { CollectionReceipt } from "../components/details/customerComponents/CollectionReceipt";

const TabContent = ({ balance, latestPurchase, name, phoneNo, address }) => {
    const { id } = useParams();

    const [updateDetails, { isSuccess, isError }] = useCustomerActionMutation();

    const [customerName, setCustomerName] = useState("");
    const [customerPhoneNo, setCustomerPhoneNo] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");

    useEffect(() => {
        if (name) {
            setCustomerName(name);
        }

        if (phoneNo) {
            setCustomerPhoneNo(phoneNo);
        }

        if (address) {
            setCustomerAddress(address);
        }
    }, [name, phoneNo, address]);

    useEffect(() => {
        if (isSuccess) {
            toast.success("Customer details has been updated");
        } else if (isError) {
            toast.error("Failed to saved detail! Please contact the Admin");
        }
    }, [isSuccess, isError]);

    return (
        <div className="flex-lg-row-fluid ms-lg-15">
            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
                <li className="nav-item">
                    <a
                        className="nav-link text-active-primary pb-4 active"
                        data-bs-toggle="tab"
                        href="#kt_ecommerce_customer_overview"
                    >
                        Overview
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link text-active-primary pb-4"
                        data-bs-toggle="tab"
                        href="#kt_ecommerce_customer_general"
                    >
                        General Settings
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link text-active-primary pb-4"
                        data-bs-toggle="tab"
                        href="#collection_receipt"
                    >
                        Collection Receipt
                    </a>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div
                    className="tab-pane fade show active"
                    id="kt_ecommerce_customer_overview"
                    role="tabpanel"
                >
                    <div className="row row-cols-1 row-cols-md-2 mb-6 mb-xl-9">
                        <TotalBalance balance={balance} />
                        <ActiveMember latestPurchase={latestPurchase} />
                    </div>
                    <TransactionHistory />
                </div>
                <div
                    className="tab-pane fade"
                    id="kt_ecommerce_customer_general"
                    role="tabpanel"
                >
                    <div className="card pt-4 mb-6 mb-xl-9">
                        <div className="card-header border-0">
                            <div className="card-title">
                                <h2>Profile</h2>
                            </div>
                        </div>
                        <div className="card-body pt-0 pb-5">
                            <div className="mb-7">
                                <label className="fs-6 fw-semibold mb-2">
                                    <span>Update Avatar</span>
                                    <i
                                        className="fas fa-exclamation-circle ms-1 fs-7"
                                        data-bs-toggle="tooltip"
                                        title="Allowed file types: png, jpg, jpeg."
                                    />
                                </label>
                                <div className="mt-1">
                                    <style
                                        dangerouslySetInnerHTML={{
                                            __html: ".image-input-placeholder { background-image: url('assets/media/svg/files/blank-image.svg'); } [data-theme=\"dark\"] .image-input-placeholder { background-image: url('assets/media/svg/files/blank-image-dark.svg'); }",
                                        }}
                                    />
                                    <div
                                        className="image-input image-input-outline image-input-placeholder"
                                        data-kt-image-input="true"
                                    >
                                        <div
                                            className="image-input-wrapper w-125px h-125px"
                                            style={{
                                                backgroundImage:
                                                    "url(assets/media/avatars/300-1.jpg)",
                                            }}
                                        />
                                        <label
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            data-kt-image-input-action="change"
                                            data-bs-toggle="tooltip"
                                            title="Change avatar"
                                        >
                                            <i className="bi bi-pencil-fill fs-7" />
                                            <input
                                                type="file"
                                                name="avatar"
                                                accept=".png, .jpg, .jpeg"
                                            />
                                            <input
                                                type="hidden"
                                                name="avatar_remove"
                                            />
                                        </label>
                                        <span
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            data-kt-image-input-action="cancel"
                                            data-bs-toggle="tooltip"
                                            title="Cancel avatar"
                                        >
                                            <i className="bi bi-x fs-2" />
                                        </span>
                                        <span
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            data-kt-image-input-action="remove"
                                            data-bs-toggle="tooltip"
                                            title="Remove avatar"
                                        >
                                            <i className="bi bi-x fs-2" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="fv-row mb-7">
                                <label className="fs-6 fw-semibold mb-2 required">
                                    Customer Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder
                                    name="customerName"
                                    value={customerName}
                                    onChange={(e) =>
                                        setCustomerName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="row row-cols-1 row-cols-md-2">
                                <div className="col">
                                    <div className="fv-row mb-7">
                                        <label className="fs-6 fw-semibold mb-2">
                                            <span>Phone Number</span>
                                            <i
                                                className="fas fa-exclamation-circle ms-1 fs-7"
                                                data-bs-toggle="tooltip"
                                                title="Active phone number must be used"
                                            />
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control form-control-solid"
                                            placeholder
                                            name="phoneNo"
                                            value={customerPhoneNo}
                                            onChange={(e) =>
                                                setCustomerPhoneNo(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="fv-row mb-7">
                                        <label className="fs-6 fw-semibold mb-2">
                                            <span>Address</span>
                                            <i
                                                className="fas fa-exclamation-circle ms-1 fs-7"
                                                data-bs-toggle="tooltip"
                                                title="Current address must be used"
                                            />
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            placeholder
                                            name="address"
                                            value={customerAddress}
                                            onChange={(e) =>
                                                setCustomerAddress(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button
                                    variant="outline-primary"
                                    onClick={() => {
                                        updateDetails({
                                            customer_id: id,
                                            customer_name: customerName,
                                            phone_no: phoneNo,
                                            address,
                                            endpoint: "update_customer_details",
                                            token: true,
                                        });
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="tab-pane fade"
                    id="collection_receipt"
                    role="tabpanel"
                >
                    <CollectionReceipt />
                </div>
            </div>
        </div>
    );
};

export default TabContent;
