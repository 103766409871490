import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apilink, prepareApiHeader } from "../../pages/General";

export const unitSlice = createApi({
    reducerPath: "unitApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${apilink}unitApi/graphql`,
        prepareHeaders: prepareApiHeader,
    }),
    tagTypes: ["unit"],
    endpoints: (builder) => ({
        getUnit: builder.query({
            query: (body) => ({
                url: "",
                method: "POST",
                body,
            }),
            providesTags: ["unit"],
        }),
        unitAction: builder.mutation({
            query: (forms) => ({
                url: "",
                method: "POST",
                body: forms,
            }),
            invalidatesTags: ["unit"],
        }),
    }),
});

export const { useGetUnitQuery, useUnitActionMutation } = unitSlice;
