import Cookies from "js-cookie";
import Clock from "react-live-clock";
import { useState, useEffect } from "react";

import { _hook } from "../../../pages/Login/components/_hook";
import { useUserActionMutation } from "../../../redux/user/UserApi";

const Appbar = () => {
    const [username, setUsername] = useState("");
    const [logoutSession, { isSuccess }] = useUserActionMutation();

    const onLogout = () => {
        logoutSession({ endpoint: "logout_session" });
    };

    useEffect(() => {
        if (isSuccess) {
            window.location.replace("/login");
        }
    }, [isSuccess]);

    useEffect(() => {
        const data = Cookies.get("userData");
        if (data) {
            let userInfo = JSON.parse(data);
            setUsername(userInfo.USER_NAME);
        }
    }, [Cookies]);

    return (
        <div id="kt_app_header" className="app-header">
            <div className="app-container container-fluid d-flex align-items-stretch justify-content-between">
                <div
                    className="d-flex align-items-center d-lg-none ms-n2 me-2"
                    title="Show sidebar menu"
                >
                    <div
                        className="btn btn-icon btn-active-color-primary w-35px h-35px"
                        id="kt_app_sidebar_mobile_toggle"
                    >
                        <span className="svg-icon svg-icon-1">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                    fill="currentColor"
                                />
                                <path
                                    opacity="0.3"
                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="../../demo1/dist/index.html" className="d-lg-none">
                        <img
                            alt="Logo"
                            src="dist/assets/media/logos/default-small.svg"
                            className="h-30px"
                        />
                    </a>
                </div>
                <div
                    className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
                    id="kt_app_header_wrapper"
                >
                    <div className="d-flex justify-content-center flex-column">
                        <h1
                            style={{
                                marginTop: 0,
                                lineHeight: 0.7,
                            }}
                        >
                            <Clock
                                format={"h:mm:ss A"}
                                ticking={true}
                                timezone={"Asia/Manila"}
                            />
                        </h1>
                        <p className="lh-1 font-weight-normal fs-12 mb-0">
                            <Clock
                                format={"MMMM D, YYYY - dddd"}
                                timezone={"PH/Asia"}
                            />
                        </p>
                    </div>
                    <div className="app-navbar flex-shrink-0">
                        <div
                            className="app-navbar-item ms-1 ms-lg-3"
                            id="kt_header_user_menu_toggle"
                        >
                            <div
                                className="cursor-pointer symbol symbol-35px symbol-md-40px"
                                data-kt-menu-trigger="click"
                                data-kt-menu-attach="parent"
                                data-kt-menu-placement="bottom-end"
                            >
                                <img
                                    src="/images/no-image2.jpg"
                                    alt="img"
                                ></img>
                            </div>
                            <div
                                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px"
                                data-kt-menu="true"
                            >
                                <div className="menu-item px-3">
                                    <div className="menu-content d-flex align-items-center px-3">
                                        <div className="symbol symbol-50px me-5">
                                            <img
                                                src="/images/no-image2.jpg"
                                                alt="img"
                                            ></img>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <div className="fw-bold d-flex align-items-center fs-5">
                                                Jeanica Hydraulic Trading
                                            </div>
                                            <div className="fw-semibold text-muted text-hover-primary fs-7">
                                                {username}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator my-2" />
                                <div className="menu-item px-5">
                                    <div
                                        className="menu-link px-5"
                                        onClick={() => onLogout()}
                                    >
                                        Sign Out
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Appbar;
