import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { Customer } from "./pages/People/Customer";
import { CustomerDetails } from "./pages/People/Customer/CustomerDetails";
import { Purchaser } from "./pages/People/Purchaser";
import { PurchaserDetails } from "./pages/People/Purchaser/PurchaserDetails";
import { Category } from "./pages/Catalog/Category";
import { EditCategory } from "./pages/Catalog/Category/EditCategory";
import { AddCategory } from "./pages/Catalog/Category/AddCategory";
import { Product } from "./pages/Catalog/Product";
import { EditProduct } from "./pages/Catalog/Product/EditProduct";
import { Dashboard } from "./pages/Dashboard";
import { Purchase } from "./pages/Purchase";
import { AddPurchase } from "./pages/Purchase/AddPurchase";
import { EditPurchase } from "./pages/Purchase/EditPurchase";
import { Sales } from "./pages/Sales";
import { AddSales } from "./pages/Sales/AddSales";
import { EditSales } from "./pages/Sales/EditSales";
import { Stock } from "./pages/Stock";
import { Error404 } from "./pages/Error/Error404";
import { Login } from "./pages/Login";
import { AddProduct } from "./pages/Catalog/Product/AddProduct";
import { SalesPerItem } from "./pages/Sales/SalesPerItem";
function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route exact element={<PrivateRoute />}>
                        <Route exact path="/" element={<Dashboard />} />
                        <Route exact path="/customer" element={<Customer />} />
                        <Route
                            exact
                            path="/customerDetails/:id"
                            element={<CustomerDetails />}
                        />
                        <Route
                            exact
                            path="/purchaser"
                            element={<Purchaser />}
                        />
                        <Route
                            exact
                            path="/purchaserDetails/:id"
                            element={<PurchaserDetails />}
                        />
                        <Route exact path="/category" element={<Category />} />
                        <Route
                            exact
                            path="/categoryAdd"
                            element={<AddCategory />}
                        />
                        <Route
                            exact
                            path="/categoryEdit/:id"
                            element={<EditCategory />}
                        />
                        <Route exact path="/product" element={<Product />} />
                        <Route
                            exact
                            path="/productAdd"
                            element={<AddProduct />}
                        />
                        <Route
                            exact
                            path="/productEdit/:id"
                            element={<EditProduct />}
                        />
                        <Route exact path="/purchase" element={<Purchase />} />
                        <Route
                            exact
                            path="/purchaseAdd"
                            element={<AddPurchase />}
                        />
                        <Route
                            exact
                            path="/purchaseDetails/:id"
                            element={<EditPurchase />}
                        />
                        <Route exact path="/sales" element={<Sales />} />
                        <Route
                            exact
                            path="/salesPerItem"
                            element={<SalesPerItem />}
                        />
                        <Route exact path="/salesAdd" element={<AddSales />} />
                        <Route
                            exact
                            path="/salesDetail/:id"
                            element={<EditSales />}
                        />
                        <Route exact path="/stock" element={<Stock />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Error404 />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
