import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../Pagination";

export const Table = (props) => {
    const navigate = useNavigate();
    const [tableHead, setTableHead] = useState("");
    const [tableBody, setTableBody] = useState("");

    useEffect(() => {
        let getTableHead = [];
        let getHeadField = [];

        if (props.head && props.head.length > 0) {
            props.head.map((item, index) => {
                getTableHead.push(
                    <th
                        key={index}
                        className={`
                            ${item.textAlign ? `text-${item.textAlign}` : ""} ${
                            props.sort[0] === item.field
                                ? `table-sort-${props.sort[1].toLowerCase()}`
                                : ""
                        } 
                        `}
                        onClick={() => {
                            if (item.sort) {
                                if (props.sort[0] === item.field) {
                                    props.setSort([
                                        item.field,
                                        props.sort[1] === "ASC"
                                            ? "DESC"
                                            : "ASC",
                                    ]);
                                } else {
                                    props.setSort([item.field, "ASC"]);
                                }
                            }
                        }}
                        style={{ width: item.width ? item.width : "auto" }}
                    >
                        {item.title}
                    </th>
                );
                getHeadField.push(item.field);
            });
        }

        setTableHead(getTableHead);
    }, [props.head]);

    useEffect(() => {
        let getTableBody = [];
        if (props.body && props.body.length > 0) {
            props.body.map((item, index) => {
                let getItem = [];
                if (props.head.length > 0) {
                    props.head.map((field, index) => {
                        if (item[field.field]) {
                            getItem.push(
                                <td
                                    key={index}
                                    className={
                                        field.textAlign
                                            ? `text-${field.textAlign}`
                                            : ""
                                    }
                                >
                                    {item[field.field]}
                                </td>
                            );
                        } else {
                            getItem.push(<td key={index}></td>);
                        }
                    });
                }
                getTableBody.push(
                    <tr
                        key={index}
                        style={{
                            cursor: props.handleClick ? "pointer" : "",
                        }}
                        onClick={() =>
                            props.handleClick
                                ? navigate(`/${props.handleClick}/${item.id}`)
                                : null
                        }
                    >
                        {getItem}
                    </tr>
                );
            });
        } else {
            getTableBody.push(
                <tr>
                    <td
                        colSpan={props.head.length}
                        style={{ textAlign: "center" }}
                    >
                        No matching records found
                    </td>
                </tr>
            );
        }
        setTableBody(getTableBody);
    }, [props.head, props.body]);

    return (
        <>
            <div className="table-responsive table-loading">
                {props.isLoading && (
                    <div className="table-loading-message">
                        Loading...
                        <span className="spinner-border text-primary spinner-border-sm align-middle ms-2"></span>
                    </div>
                )}
                <table
                    className={`table ${
                        props.handleClick ? "table-hover" : ""
                    } align-middle table-row-dashed fs-6 gy-5`}
                >
                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        {tableHead}
                    </tr>
                    <tbody className="fw-semibold text-gray-600">
                        {tableBody}
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    <label>
                        <select
                            className="form-select form-select-sm form-select-solid"
                            onChange={(e) =>
                                props.setActivePagination(e.target.value)
                            }
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </select>
                    </label>
                </div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <Pagination
                        active={props.active}
                        setActive={props.setActive}
                        total={props.totalPage}
                    />
                </div>
            </div>
        </>
    );
};
