import { useEffect } from "react";
import { useState } from "react";

export const Pagination = (props) => {
    const [display, setDisplay] = useState("");
    const [disableNext, setDisableNext] = useState("");
    const [disablePrevious, setDisablePrevious] = useState("");

    useEffect(() => {
        let getDisplay = [];
        if (props.total > 0) {
            let isCompress = false;
            for (let i = 0; i < props.total; i++) {
                if (props.total < 7) {
                    getDisplay.push(
                        <li
                            key={i}
                            className={`page-item ${
                                i + 1 === props.active ? "active" : ""
                            } `}
                        >
                            <a
                                className="page-link"
                                onClick={() => {
                                    props.setActive(i + 1);
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                {i + 1}
                            </a>
                        </li>
                    );
                } else {
                    if (props.active < 5) {
                        if (
                            i < props.active + 1 ||
                            i === props.total - 1 ||
                            i < 5
                        ) {
                            getDisplay.push(
                                <li
                                    key={i}
                                    className={`page-item ${
                                        i + 1 === props.active ? "active" : ""
                                    } `}
                                >
                                    <a
                                        className="page-link"
                                        onClick={() => {
                                            props.setActive(i + 1);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {i + 1}
                                    </a>
                                </li>
                            );
                        } else {
                            if (!isCompress) {
                                getDisplay.push(
                                    <li
                                        key={i}
                                        className={`page-item ${
                                            i + 1 === props.active
                                                ? "active"
                                                : ""
                                        } `}
                                    >
                                        <a className="page-link disabled">
                                            ...
                                        </a>
                                    </li>
                                );
                                isCompress = true;
                            }
                        }
                    } else if (props.total - props.active < 4) {
                        if (
                            i > props.active + 1 ||
                            i === 0 ||
                            props.total - i < 6
                        ) {
                            getDisplay.push(
                                <li
                                    className={`page-item ${
                                        i + 1 === props.active ? "active" : ""
                                    } `}
                                >
                                    <a
                                        className="page-link"
                                        onClick={() => {
                                            props.setActive(i + 1);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {i + 1}
                                    </a>
                                </li>
                            );
                        } else {
                            if (!isCompress) {
                                getDisplay.push(
                                    <li
                                        className={`page-item ${
                                            i + 1 === props.active
                                                ? "active"
                                                : ""
                                        } `}
                                    >
                                        <a className="page-link disabled">
                                            ...
                                        </a>
                                    </li>
                                );
                                isCompress = true;
                            }
                        }
                    } else {
                        if (
                            i + 1 === props.total ||
                            i === 0 ||
                            i + 1 === props.active ||
                            i + 1 === props.active - 1 ||
                            i + 1 === props.active + 1
                        ) {
                            getDisplay.push(
                                <li
                                    key={i}
                                    className={`page-item ${
                                        i + 1 === props.active ? "active" : ""
                                    } `}
                                >
                                    <a
                                        className="page-link"
                                        onClick={() => {
                                            props.setActive(i + 1);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {i + 1}
                                    </a>
                                </li>
                            );
                            if (i + 1 === props.active) {
                                isCompress = false;
                            }
                        } else {
                            if (!isCompress) {
                                getDisplay.push(
                                    <li
                                        key={i}
                                        className={`page-item ${
                                            i + 1 === props.active
                                                ? "active"
                                                : ""
                                        } `}
                                    >
                                        <a className="page-link disabled">
                                            ...
                                        </a>
                                    </li>
                                );
                            }

                            isCompress = true;
                        }
                    }
                }
            }
        }
        setDisplay(getDisplay);
    }, [props.active, props.total]);

    useEffect(() => {
        setDisablePrevious(props.active === 1 ? "disabled" : "");
        setDisableNext(props.active === props.total ? "disabled" : "");
    }, [props.active, props.total]);

    return (
        <ul
            className={`pagination ${props.sx !== undefined ? props.sx : ""}`}
            {...props}
        >
            <li className={`page-item previous ${disablePrevious}`}>
                <a
                    className="page-link"
                    onClick={() => {
                        props.setActive(props.active - 1);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <i className="previous" />
                </a>
            </li>
            {display}
            <li className={`page-item next ${disableNext}`}>
                <a
                    className="page-link"
                    onClick={() => {
                        props.setActive(props.active + 1);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <i className="next" />
                </a>
            </li>
        </ul>
    );
};
