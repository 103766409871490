let jsFile = [
    "/src/js/components/util.js",
    "/src/js/components/blockui.js",
    "/src/js/components/cookie.js",
    "/src/js/components/dialer.js",
    "/src/js/components/drawer.js",
    "/src/js/components/event-handler.js",
    "/src/js/components/feedback.js",
    "/src/js/components/image-input.js",
    "/src/js/components/menu.js",
    "/src/js/components/password-meter.js",
    "/src/js/components/scroll.js",
    "/src/js/components/scrolltop.js",
    "/src/js/components/search.js",
    "/src/js/components/stepper.js",
    "/src/js/components/sticky.js",
    "/src/js/components/swapper.js",
    "/src/js/components/toggle.js",
    "/src/js/layout/app.js",
    "/src/js/custom/apps/ecommerce/sales/save-order.js",
];
jsFile.map((url) => {
    var head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.type = "text/javascript";

    head.appendChild(script);
    // document.body.appendChild(script);
});
