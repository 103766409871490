import React from "react";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import {
    useCustomerActionMutation,
    useGetCustomerQuery,
} from "../../../../../redux/people/CustomerApi";
import { Pagination } from "../../../../../components/base";

export const CollectionReceipt = () => {
    const { id } = useParams();
    const [search, setSearch] = useState("");
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPage, setTotalPage] = useState(0);
    const [receiptData, setReceiptData] = useState([]);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            receiptNumber: "",
            date: moment().format("YYYY-MM-DD"),
            customer: id,
            total: 0,
            discount: 0,
            paid: "",
        },
    });
    const [selectedReceipt, setSelectedReceipt] = useState([]);

    const [addDetails, { isSuccess: mutationSuccess, isError: mutationError }] =
        useCustomerActionMutation();

    const {
        data,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetCustomerQuery({
        endpoint: "get_customer_unpaid_transaction_history",
        customer_id: id,
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        search,
        sort: ["sales_date", "DESC"],
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (data) {
                setTotalPage(Math.ceil(data.total / activePagination));
                data.data.map((item) => {
                    getData.push({
                        id: item.sales_no,
                        sales_date: moment(item.sales_date).format("LL"),
                        receipt_name: item.receipt_name,
                        receipt_number: item.receipt_number,
                        po_number: item.po_number,
                        sales_amount_total: item.sales_amount_total,
                    });
                });
            }
        }
        setReceiptData(getData);
    }, [isSuccess, data, fetchLoading]);

    useEffect(() => {
        if (mutationSuccess) {
            Swal.fire(
                "Success!",
                "Successfully saved collection receipt!",
                "success"
            );
        } else if (mutationError) {
            Swal.fire(
                "Error!",
                "There is something went wrong. Please contact the administrator!",
                "error"
            );
        }
    }, [mutationSuccess, mutationError]);

    const myFunction = (data, amount) => {
        let getReceipts = selectedReceipt.map((x) => x.sales_no);

        if (getReceipts.includes(data.sales_no)) {
            setSelectedReceipt([
                ...selectedReceipt.filter(
                    (item) => item.sales_no !== data.sales_no
                ),
            ]);
            setValue("total", Number(watch("total")) - Number(amount));
        } else {
            setSelectedReceipt([...selectedReceipt, data]);
            setValue("total", Number(watch("total")) + Number(amount));
        }
    };

    const submitReceipt = (data) => {
        if (selectedReceipt.length > 0) {
            addDetails({
                data,
                receipts: selectedReceipt,
                endpoint: "add_collection_receipt",
                token: true,
            });
        } else {
            Swal.fire(
                "Warning!",
                "Required to select at least one receipt!",
                "warning"
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(submitReceipt)}>
            <div className="card pt-4 mb-6 mb-xl-9">
                <div className="card-header border-0">
                    <div className="card-title">
                        <div className="d-flex flex-column">
                            <h2>Unpaid Receipt </h2>
                            <small>Select a receipt that will be pay</small>
                        </div>
                    </div>
                    <div class="card-toolbar">
                        <Form.Control
                            className="form-control form-control-solid"
                            size="sm"
                            placeholder="Search Transaction"
                            onChange={(e) => {
                                setTimeout(() => {
                                    setSearch(e.target.value);
                                    setActive(1);
                                }, 1000);
                            }}
                        />
                    </div>
                </div>
                <div className="card-body pt-0 pb-5">
                    {totalPage > 0 ? (
                        <>
                            <div className="table-responsive ">
                                <table className="table table-hover gy-7 gs-7">
                                    <thead>
                                        <tr className="fw-semibold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                            <th className="w-auto">Date</th>
                                            <th className="w-auto text-center">
                                                Type
                                            </th>
                                            <th className="w-auto text-center">
                                                Invoice
                                            </th>
                                            <th className="w-auto text-center">
                                                PO
                                            </th>
                                            <th className="w-auto text-end">
                                                Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="fw-semibold text-gray-600">
                                        {receiptData.length > 0 &&
                                            receiptData.map((item) => {
                                                let getReceipts =
                                                    selectedReceipt.map(
                                                        (x) => x.sales_no
                                                    );
                                                return (
                                                    <tr
                                                        onClick={() => {
                                                            const data = {
                                                                sales_no:
                                                                    item.id,
                                                                receiptNumber:
                                                                    item.receipt_number,
                                                                price: item.sales_amount_total,
                                                            };
                                                            myFunction(
                                                                data,
                                                                item.sales_amount_total
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                            backgroundColor:
                                                                getReceipts.includes(
                                                                    item.id
                                                                )
                                                                    ? "#009ef742"
                                                                    : "white",
                                                        }}
                                                    >
                                                        <td>
                                                            {item.sales_date}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.receipt_name}
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                item.receipt_number
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {item.po_number}
                                                        </td>
                                                        <td className="text-end">
                                                            ₱{" "}
                                                            {Number(
                                                                item.sales_amount_total
                                                            ).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                    <label>
                                        <select
                                            className="form-select form-select-sm form-select-solid"
                                            onChange={(e) =>
                                                setActivePagination(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                    <Pagination
                                        active={active}
                                        setActive={setActive}
                                        total={totalPage}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        "No Data Available"
                    )}
                </div>
            </div>
            <div className="card pt-4 mb-6 mb-xl-9">
                <div className="card-header border-0">
                    <div className="card-title">
                        <h2>Receipt Information</h2>
                    </div>
                </div>
                <div className="card-body pt-0 pb-5">
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-7">
                                <label className="fs-6 fw-semibold mb-2">
                                    <span>Receipt Number</span>
                                </label>
                                <input
                                    type="number"
                                    className={`form-control form-control-solid${
                                        errors.receiptNumber
                                            ? " error-field"
                                            : ""
                                    }`}
                                    placeholder="Enter receipt number"
                                    {...register("receiptNumber", {
                                        required: true,
                                    })}
                                />
                                {errors.receiptNumber && (
                                    <span className="error-msg">
                                        Receipt number is required*
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col">
                            <div className="fv-row mb-7">
                                <label className="fs-6 fw-semibold mb-2">
                                    <span>Date</span>
                                </label>
                                <Controller
                                    control={control}
                                    name="date"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Flatpickr
                                            data-enable-time
                                            value={value}
                                            className={`form-control form-control-solid${
                                                errors.date
                                                    ? " error-field"
                                                    : ""
                                            }`}
                                            placeholder="Enter receipt date"
                                            onChange={onChange}
                                            options={{
                                                enableTime: false,
                                                altFormat: "F j, Y",
                                            }}
                                        />
                                    )}
                                />
                                {errors.date && (
                                    <span className="error-msg">
                                        Receipt date is required*
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <br />
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-7 d-flex justify-content-between gap-10">
                                <b>Subtotal: </b>{" "}
                                <input
                                    type="number"
                                    className="form-control form-control-transparent text-end w-75"
                                    value={watch("total")}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-7 d-flex justify-content-between gap-10">
                                <b>Discount: </b>{" "}
                                <input
                                    type="number"
                                    className="form-control form-control-solid text-end w-75"
                                    placeholder="Enter receipt number"
                                    {...register("discount")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-7 d-flex justify-content-between gap-10">
                                <b>Payment: </b>{" "}
                                <div className="w-75">
                                    <input
                                        type="number"
                                        className={`form-control form-control-solid text-end${
                                            errors.paid ? " error-field" : ""
                                        }`}
                                        placeholder="Enter receipt number"
                                        {...register("paid", {
                                            required: true,
                                        })}
                                    />
                                    {errors.paid && (
                                        <span className="error-msg">
                                            Receipt payment is required*
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-7 d-flex justify-content-between gap-10">
                                <b>Change: </b>{" "}
                                <input
                                    type="number"
                                    className="form-control form-control-transparent text-end w-75"
                                    value={
                                        watch("paid") > watch("total")
                                            ? watch("paid") - watch("total")
                                            : 0
                                    }
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-7 d-flex justify-content-between gap-10">
                                <b>Net: </b>{" "}
                                <input
                                    type="number"
                                    className="form-control form-control-transparent text-end w-75"
                                    value={watch("total") - watch("discount")}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                            <div className="fv-row mb-7 d-flex justify-content-between gap-10">
                                <b>Due: </b>{" "}
                                <input
                                    type="number"
                                    className="form-control form-control-transparent text-end w-75"
                                    value={
                                        watch("paid") < watch("total")
                                            ? watch("total") -
                                              watch("discount") -
                                              watch("paid")
                                            : 0
                                    }
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="fv-row mb-7 d-flex justify-content-end">
                                <Button
                                    // onClick={() => {
                                    //     if (
                                    //         toSubmitData.receiptNumber !==
                                    //             0 &&
                                    //         selectedReceipt.length > 0
                                    //     ) {
                                    //         addDetails({
                                    //             data: toSubmitData,
                                    //             date: toSubmitData.date,
                                    //             customer: id,
                                    //             discount:
                                    //                 toSubmitData.discount,
                                    //             totalPrice:
                                    //                 toSubmitData.total,
                                    //             paid: toSubmitData.paid,
                                    //             receiptNumber:
                                    //                 toSubmitData.receiptNumber,
                                    //             receipts: selectedReceipt,
                                    //             endpoint:
                                    //                 "add_collection_receipt",
                                    //             token: true,
                                    //         });
                                    //     } else {
                                    //         if (
                                    //             toSubmitData.receiptNumber ===
                                    //             0
                                    //         ) {
                                    //             Swal.fire(
                                    //                 "Warning!",
                                    //                 "Receipt field is required to input!",
                                    //                 "warning"
                                    //             );
                                    //         } else if (
                                    //             selectedReceipt.length > 0
                                    //         ) {
                                    //             Swal.fire(
                                    //                 "Warning!",
                                    //                 "Required to select at least one receipt!",
                                    //                 "warning"
                                    //             );
                                    //         }
                                    //     }
                                    // }}
                                    type="submit"
                                >
                                    Submit Payment
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
