export const CardBody = (props) => {
    return (
        <>
            {props.overlay ? (
                ""
            ) : (
                <div
                    className={`card-body ${
                        props.scroll ? "card-scroll" : ""
                    } ${props.sx !== undefined ? props.sx : ""}`}
                    {...props}
                >
                    {props.children}
                </div>
            )}
        </>
    );
};
