import { useState } from "react";
import { useParams } from "react-router-dom";

import { Container, Toolbar } from "../../components/layout";
import { OrderDetails } from "./components/OrderDetails";
import { SelectProduct } from "./components/SelectProduct";
import { useGetPurchaseQuery } from "../../redux/transaction/PurchaseApi";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

export const EditPurchase = () => {
    const { id } = useParams();

    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
        {
            label: "Purchase",
            link: "/purchase",
        },
    ];

    const [company, setCompany] = useState("");
    const [date, setDate] = useState("");
    const [purchaseNumber, setPurchaseNumber] = useState("");
    const [productSelect, setProductSelect] = useState([]);
    const [discount, setDiscount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const { data, isSuccess } = useGetPurchaseQuery({
        endpoint: "get_purchase_details",
        purchase_no: id,
        token: true,
    });

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                if (data.purchase_company) {
                    let item = data.purchase_company;
                    setCompany(item.company_name);
                    setDate(item.purchase_date);
                    setDiscount(item.purchase_discount);
                    setPurchaseNumber(item.pur_no);
                    setTotalPrice(item.grand_total);
                }
                if (data.purchase.length > 0) {
                    setProductSelect(data.purchase);
                }
            }
        }
    }, [isSuccess, data]);

    return (
        <>
            <Toolbar label="Details Purchase" breadcrumb={breadcrumb} />
            <Container>
                <div className="d-flex flex-column flex-lg-row">
                    <Row>
                        <Col xs={12} md={3}>
                            <OrderDetails
                                viewing
                                company={company}
                                date={date}
                                purchaseNumber={purchaseNumber}
                                discount={discount}
                            />
                        </Col>
                        <Col xs={12} md={9}>
                            <div className="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-10">
                                <SelectProduct
                                    viewing
                                    productSelect={productSelect}
                                    setProductSelect={setProductSelect}
                                    totalPrice={totalPrice}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
