import { useEffect, useState } from "react";

const Sidebar = ({ name, phoneNo, address, latestPurchase }) => {
    const [customerName, setCustomerName] = useState("");
    const [customerPhoneNo, setCustomerPhoneNo] = useState(
        "-No Data Available-"
    );
    const [customerAddress, setCustomerAddress] = useState(
        "-No Data Available-"
    );

    useEffect(() => {
        if (name) {
            setCustomerName(name);
        }

        if (phoneNo) {
            setCustomerPhoneNo(phoneNo);
        }

        if (address) {
            setCustomerAddress(address);
        }
    }, [name, phoneNo, address]);
    return (
        <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
            <div className="card mb-5 mb-xl-8">
                <div className="card-body pt-15">
                    <div className="d-flex flex-center flex-column mb-5">
                        <div className="symbol symbol-150px symbol-circle mb-7">
                            <img src="/images/no-image2.jpg" alt="image" />
                        </div>
                        <a
                            href="#"
                            className="fs-3 text-gray-800 text-hover-primary fw-bold mb-10"
                        >
                            {customerName}
                        </a>
                    </div>
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bold">Details</div>
                        <div className="badge badge-light-success d-inline">
                            Active
                        </div>
                    </div>
                    <div className="separator separator-dashed my-3" />
                    <div className="pb-5 fs-6">
                        <div className="fw-bold mt-5">Phone Number</div>
                        <div className="text-gray-600">{customerPhoneNo}</div>
                        <div className="fw-bold mt-5">Address</div>
                        <div className="text-gray-600">{customerAddress}</div>
                        <div className="fw-bold mt-5">Latest Transaction</div>
                        <div className="text-gray-600">
                            <a
                                href="#"
                                className="text-gray-600 text-hover-primary"
                            >
                                {latestPurchase}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
