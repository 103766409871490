import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apilink, prepareApiHeader } from "../../pages/General";

export const salesSlice = createApi({
    reducerPath: "salesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${apilink}salesApi/graphql`,
        prepareHeaders: prepareApiHeader,
    }),
    tagTypes: ["sales"],
    endpoints: (builder) => ({
        getSales: builder.query({
            query: (body) => ({
                url: "",
                method: "POST",
                body,
            }),
            providesTags: ["sales"],
        }),
        salesAction: builder.mutation({
            query: (forms) => ({
                url: "",
                method: "POST",
                body: forms,
            }),
            invalidatesTags: ["sales"],
        }),
    }),
});

export const { useGetSalesQuery, useSalesActionMutation } = salesSlice;
