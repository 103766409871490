export const apilink = "https://jht.invesystem.xyz/";
// export const apilink = "http://localhost/jht/";

export const replaceImage = (error) => {
    error.target.src = "images/no-image2.jpg";
};

export const getCookie = (key) => {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
};

export const prepareApiHeader = (headers) => {
    let token = getCookie("token");
    if (token) {
        headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
};
