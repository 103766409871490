import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export const GroupItem = (props) => {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Group Item
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <Form className="mb-2">
                            <Form.Group className="fv-row">
                                <Form.Label className="required form-label">
                                    Product Name
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    {...props.register("groupItem.name", {
                                        required: true,
                                    })}
                                />
                                <div className="text-muted fs-7">
                                    Set the product number for the group item.
                                </div>
                            </Form.Group>
                        </Form>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <Form>
                                    <Form.Group className="fv-row">
                                        <Form.Label className="required form-label">
                                            Qty
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            {...props.register(
                                                "groupItem.qty",
                                                {
                                                    required: true,
                                                }
                                            )}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the product quantity for the
                                            group item.
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                            <div className="col-md-6 col-12">
                                <Form>
                                    <Form.Group className="fv-row">
                                        <Form.Label className="required form-label">
                                            Price
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            {...props.register(
                                                "groupItem.price",
                                                {
                                                    required: true,
                                                }
                                            )}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the product price for the group
                                            item.
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div
                            className="row  border border-dashed rounded pt-3 pb-1 px-2 mb-5 mh-500px overflow-scroll"
                            id="kt_ecommerce_edit_order_selected_products"
                        >
                            <div
                                data-kt-ecommerce-edit-order-filter="product"
                                data-kt-ecommerce-edit-order-id="product_1"
                            >
                                <Row
                                    style={{
                                        borderBottom: "1px dashed #8080806b",
                                        paddingBottom: 10,
                                    }}
                                >
                                    <Col className="text-center m-auto" xxl={8}>
                                        <span class="fw-bold ms-3">
                                            Product
                                        </span>
                                    </Col>
                                    <Col className="text-center m-auto" xxl={4}>
                                        <span class="fw-bold ms-3">
                                            Category
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                            {props.productSelectedListModal}
                            {props.productSelectedListModal.length === 0 && (
                                <span className="w-100 text-muted mt-5">
                                    Select one or more products from the list
                                    below by clicking the product.
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>
                <Button onClick={props.submit}>Create Group Item</Button>
            </Modal.Footer>
        </Modal>
    );
};
