import Flatpickr from "react-flatpickr";
import moment from "moment";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";

import { useGetSalesQuery } from "../../../redux/transaction/SalesApi";

export const OrderDetails = ({ viewing, register, setValue, watch }) => {
    const [search, setSearch] = useState("");
    const [options, setOptions] = useState("");

    const { data, isSuccess } = useGetSalesQuery({
        endpoint: "get_sales_filter",
        token: true,
        search,
    });

    useEffect(() => {
        let optionsData = [];
        if (isSuccess) {
            if (data) {
                data.map((item) => {
                    optionsData.push({
                        value: item.customer_id,
                        label: item.customer_name,
                    });
                });
            }
        }
        setOptions(optionsData);
    }, [isSuccess, data]);

    return (
        <div className="d-flex flex-column gap-7 gap-lg-10 mb-7 me-lg-10">
            <Card className="card-flush py-4">
                <Card.Header>
                    <div className="card-title">
                        <h2>Receipt Info</h2>
                    </div>
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="d-flex flex-column gap-10">
                        <Form>
                            <Form.Group className="fv-row">
                                <Form.Label className="required form-label">
                                    Invoice Type
                                </Form.Label>
                                {!viewing ? (
                                    <>
                                        <Select
                                            className="basic-single mb-2"
                                            classNamePrefix="select"
                                            onChange={(e) =>
                                                setValue("receipt", e.value)
                                            }
                                            options={[
                                                {
                                                    value: 2,
                                                    label: "Order Slip",
                                                },
                                                {
                                                    value: 3,
                                                    label: "Cash Invoice",
                                                },
                                                {
                                                    value: 4,
                                                    label: "Charge Invoice",
                                                },
                                                {
                                                    value: 6,
                                                    label: "Delivery Receipt (VAT)",
                                                },
                                            ]}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the invoice type to process.
                                        </div>
                                    </>
                                ) : (
                                    <h3 className="text-muted">
                                        {watch("receipt")}
                                    </h3>
                                )}
                            </Form.Group>
                        </Form>
                        <Form>
                            <Form.Group className="fv-row">
                                <Form.Label className="required form-label">
                                    Receipt Number
                                </Form.Label>
                                {!viewing ? (
                                    <>
                                        <Form.Control
                                            type="number"
                                            {...register("receiptNumber", {
                                                required: true,
                                            })}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the receipt number to process.
                                        </div>
                                    </>
                                ) : (
                                    <h3 className="text-muted">
                                        {watch("receiptNumber")}
                                    </h3>
                                )}
                            </Form.Group>
                        </Form>
                        {watch("receipt") === 2 || watch("receipt") === 4 ? (
                            <>
                                <div class="separator"></div>
                                <Form>
                                    <Form.Group className="fv-row">
                                        <Form.Label className="form-label">
                                            PO Number
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            {...register("po")}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the PO number to process.
                                        </div>
                                    </Form.Group>
                                </Form>
                            </>
                        ) : (
                            ""
                        )}
                        {viewing && watch("po") && (
                            <>
                                <div class="separator"></div>
                                <Form>
                                    <Form.Group className="fv-row">
                                        <Form.Label className="form-label">
                                            PO Number
                                        </Form.Label>
                                        <h3 className="text-muted">
                                            {watch("po")}
                                        </h3>
                                    </Form.Group>
                                </Form>
                            </>
                        )}
                    </div>
                </Card.Body>
            </Card>
            <Card className="card-flush py-4">
                <Card.Header>
                    <div className="card-title">
                        <h2>Sales Details</h2>
                    </div>
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="d-flex flex-column gap-10">
                        <Form>
                            <Form.Group className="fv-row">
                                <Form.Label className="required form-label">
                                    Customer Name
                                </Form.Label>
                                {!viewing ? (
                                    <>
                                        <CreatableSelect
                                            isClearable
                                            className="basic-single mb-2"
                                            classNamePrefix="form-select"
                                            onChange={(e) => {
                                                if (e.__isNew__) {
                                                    setValue("salesStatus", 1);
                                                } else {
                                                    setValue("salesStatus", 2);
                                                }
                                                setValue("customer", e.value);
                                            }}
                                            options={options}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the customer to process.
                                        </div>
                                    </>
                                ) : (
                                    <h3 className="text-muted">
                                        {watch("customer")}
                                    </h3>
                                )}
                            </Form.Group>
                        </Form>
                        <Form>
                            <Form.Group className="fv-row">
                                <Form.Label className="required form-label">
                                    Sales Date
                                </Form.Label>
                                {!viewing ? (
                                    <>
                                        <Flatpickr
                                            data-enable-time
                                            value={watch("date")}
                                            className="form-control mb-2"
                                            onChange={(data) => {
                                                setValue(
                                                    "date",
                                                    moment(
                                                        new Date(data)
                                                    ).format("YYYY-MM-DD")
                                                );
                                            }}
                                            options={{
                                                enableTime: false,
                                                altFormat: "F j, Y",
                                            }}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the sales date to process.
                                        </div>
                                    </>
                                ) : (
                                    <h3 className="text-muted">
                                        {moment(watch("date")).format("LL")}
                                    </h3>
                                )}
                            </Form.Group>
                        </Form>
                    </div>
                </Card.Body>
            </Card>
            <Card className="card-flush py-4">
                <Card.Header>
                    <div className="card-title">
                        <h2>Sales Transactions</h2>
                    </div>
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="d-flex flex-column gap-10">
                        <Form>
                            <Form.Group className="fv-row">
                                <Form.Label className="form-label">
                                    Discount
                                </Form.Label>
                                {!viewing ? (
                                    <>
                                        <Form.Control
                                            type="number"
                                            {...register("discount")}
                                        />
                                        <div className="text-muted fs-7">
                                            Set the discount of sales to adjust
                                            the total purchase.
                                        </div>
                                    </>
                                ) : (
                                    <h3 className="text-muted">
                                        ₱ {watch("discount")}.00
                                    </h3>
                                )}
                            </Form.Group>
                        </Form>
                        {viewing && (
                            <Form>
                                <Form.Group className="fv-row">
                                    <Form.Label className="form-label">
                                        Paid
                                    </Form.Label>
                                    <h3 className="text-muted">
                                        ₱ {watch("paid")}.00
                                    </h3>
                                </Form.Group>
                            </Form>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};
