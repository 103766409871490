import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Toolbar = (props) => {
    const navigate = useNavigate();
    const [data, setData] = useState("");

    useEffect(() => {
        let getData = [];
        if (props.breadcrumb) {
            props.breadcrumb.map((item, index) => {
                getData.push(
                    <li className="breadcrumb-item text-muted">
                        <a
                            onClick={() => navigate(item.link)}
                            className="text-muted text-hover-primary"
                            style={{ cursor: "pointer" }}
                        >
                            {item.label}
                        </a>
                    </li>
                );
                if (props.breadcrumb.length > index) {
                    getData.push(
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px" />
                        </li>
                    );
                }
            });
            getData.push(
                <li className="breadcrumb-item text-muted">{props.label}</li>
            );
        }
        setData(getData);
    }, [props.breadcrumb]);

    return (
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div
                id="kt_app_toolbar_container"
                className="app-container container-xxl d-flex flex-stack"
            >
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        {props.label}
                    </h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        {data}
                    </ul>
                </div>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    {props.action}
                </div>
            </div>
        </div>
    );
};
