import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
    const navigate = useNavigate();
    const [sidebarList, setsidebarList] = useState([
        {
            label: "People",
            icon: <i class="fa-solid fa-people-group"></i>,
            sublabel: [
                { label: "Customers", link: "/customer" },
                { label: "Purchasers", link: "/purchaser" },
            ],
        },
        {
            label: "Catalog",
            icon: <i class="fa-solid fa-shop"></i>,
            sublabel: [
                { label: "Category", link: "/category" },
                { label: "Product", link: "/product" },
            ],
        },
        {
            label: "Purchase",
            icon: <i class="fa-solid fa-bag-shopping"></i>,
            sublabel: [{ label: "Purchase", link: "/purchase" }],
        },
        {
            label: "Sales",
            icon: <i class="fa-solid fa-cash-register"></i>,
            sublabel: [
                { label: "Sales", link: "/sales" },
                { label: "Sales per Item", link: "/salesPerItem" },
            ],
        },
        {
            label: "Stock",
            icon: <i class="fa-solid fa-boxes-stacked"></i>,
            sublabel: [{ label: "Stock", link: "/stock" }],
        },
    ]);

    const [sidebarAccess, setsidebarAccess] = useState("");

    useEffect(() => {
        let getsidebar = [];
        if (sidebarList) {
            sidebarList.map((item) => {
                let getsublabel = [];
                if (item.sublabel) {
                    item.sublabel.map((subitem) => {
                        getsublabel.push(
                            <div className="menu-sub menu-sub-accordion">
                                <div className="menu-item">
                                    <a
                                        className="menu-link"
                                        onClick={() => navigate(subitem.link)}
                                    >
                                        <span className="menu-bullet">
                                            <span className="bullet bullet-dot" />
                                        </span>
                                        <span className="menu-title">
                                            {subitem.label}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        );
                    });
                }
                getsidebar.push(
                    <div
                        data-kt-menu-trigger="click"
                        className="menu-item menu-accordion"
                    >
                        <span className="menu-link">
                            <span className="menu-icon">
                                <span className="svg-icon svg-icon-2">
                                    {item.icon}
                                </span>
                            </span>
                            <span className="menu-title">{item.label}</span>
                            <span className="menu-arrow" />
                        </span>
                        {getsublabel}
                    </div>
                );
            });
        }

        setsidebarAccess(getsidebar);
    }, [sidebarList]);

    return (
        <div
            id="kt_app_sidebar"
            className="app-sidebar flex-column"
            data-kt-drawer="true"
            data-kt-drawer-name="app-sidebar"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="225px"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
        >
            <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
                <div>
                    <img
                        alt="Logo"
                        src="images/invesystem.webp"
                        className="h-25px app-sidebar-logo-default"
                    />
                    <img
                        alt="Logo"
                        src="images/invesystem_icon1.png"
                        className="h-25px app-sidebar-logo-minimize"
                    />
                </div>
                <div
                    id="kt_app_sidebar_toggle"
                    className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
                    data-kt-toggle="true"
                    data-kt-toggle-state="active"
                    data-kt-toggle-target="body"
                    data-kt-toggle-name="app-sidebar-minimize"
                >
                    <span className="svg-icon svg-icon-2 rotate-180">
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.5"
                                d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                                fill="currentColor"
                            />
                            <path
                                d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                                fill="currentColor"
                            />
                        </svg>
                    </span>
                </div>
            </div>
            <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
                <div
                    id="kt_app_sidebar_menu_wrapper"
                    className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="true"
                    data-kt-scroll-height="auto"
                    data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                    data-kt-scroll-wrappers="#kt_app_sidebar_menu"
                    data-kt-scroll-offset="5px"
                    data-kt-scroll-save-state="true"
                >
                    <div
                        className="menu menu-column menu-rounded menu-sub-indention px-3"
                        id="#kt_app_sidebar_menu"
                        data-kt-menu="true"
                        data-kt-menu-expand="false"
                    >
                        <div
                            className="menu-item"
                            onClick={() => navigate("/")}
                        >
                            <div className="menu-link">
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="2"
                                                y="2"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                            ></rect>
                                            <rect
                                                opacity="0.3"
                                                x="13"
                                                y="2"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                            ></rect>
                                            <rect
                                                opacity="0.3"
                                                x="13"
                                                y="13"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                            ></rect>
                                            <rect
                                                opacity="0.3"
                                                x="2"
                                                y="13"
                                                width="9"
                                                height="9"
                                                rx="2"
                                                fill="currentColor"
                                            ></rect>
                                        </svg>
                                    </span>
                                </span>
                                <span className="menu-title">Dashboard</span>
                            </div>
                        </div>
                        <div className="menu-item pt-5">
                            <div className="menu-content">
                                <span className="menu-heading fw-bold text-uppercase fs-7">
                                    Components
                                </span>
                            </div>
                        </div>
                        {sidebarAccess}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
