import {
    useGetDataQuery,
    useUserActionMutation,
} from "../../../redux/user/UserApi";

const defaultImage = "url(assets/media/auth/bg4.jpg)";

export const _hook = () => {
    const {
        data: fetchCookies,
        isSuccess: cookieIsSuccess,
        isLoading: cookieIsLoading,
        isError: cookieIsError,
    } = useGetDataQuery({
        endpoint: "",
        token: true,
    });

    const [logoutSession] = useUserActionMutation({
        endpoint: "logout_session",
    });

    const isSuccess = cookieIsSuccess;
    const isLoading = cookieIsLoading;
    const isError = cookieIsError;

    return {
        setBackgroundImage: defaultImage,
        data: fetchCookies,
        logoutSession,
        isSuccess,
        isLoading,
        isError,
    };
};
