import { useEffect, useState } from "react";

import { Card, CardBody, CardHeader, Table } from "../../components/base";
import { replaceImage } from "../General";
import { Container, Toolbar } from "../../components/layout";
import { useGetProductQuery } from "../../redux/catalog/ProductApi";
import { CategorySearch } from "./CategorySearch";
import { MoveQuantity } from "./_components/MoveQuantity";

export const Stock = () => {
    const breadcrumb = [
        {
            label: "Dashboard",
            link: "/",
        },
    ];

    const header = [
        {
            field: "item_name",
            title: "Item",
            sort: true,
            width: "30%",
        },
        {
            field: "category_name",
            title: "Category",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "unit_name",
            title: "Unit",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "stock_qty",
            title: "Stock",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "side_stock",
            title: "Side Stock",
            sort: true,
            width: "10%",
            textAlign: "center",
        },
        {
            field: "purchase_rate",
            title: "Purchase Rate",
            sort: true,
            width: "15%",
            textAlign: "end",
        },
        {
            field: "stock_rate",
            title: "Stock Rate",
            sort: true,
            width: "15%",
            textAlign: "end",
        },
    ];

    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("");
    const [sort, setSort] = useState(["category_name", "ASC"]);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState(1);
    const [activePagination, setActivePagination] = useState(5);
    const [totalPagination, setTotalPagination] = useState(0);
    const [data, setData] = useState([]);

    const [modalShow, setModalShow] = useState(false);
    const [dataModal, setDataModal] = useState([]);

    const {
        data: categoryData,
        isSuccess,
        isLoading: fetchLoading,
    } = useGetProductQuery({
        endpoint: "get_product",
        token: true,
        active: activePagination * active - activePagination,
        pagination: activePagination,
        search,
        category,
        sort,
    });

    useEffect(() => {
        let getData = [];
        if (isSuccess) {
            if (categoryData) {
                setTotalPagination(
                    Math.ceil(categoryData.total / activePagination)
                );
                categoryData.data.map((item) => {
                    getData.push({
                        id: item.stock_no,
                        item_name: (
                            <div
                                className="d-flex align-items-center"
                                data-kt-ecommerce-edit-order-filter="product"
                                data-kt-ecommerce-edit-order-id="product_1"
                            >
                                <div className="symbol symbol-50px">
                                    <span
                                        className="symbol-label"
                                        style={{
                                            backgroundImage:
                                                "url(assets/media//stock/ecommerce/1dfd.gif)",
                                        }}
                                        onError={replaceImage}
                                    />
                                </div>
                                <div className="ms-5">
                                    <div className="text-gray-800 fs-5 fw-bold">
                                        {item.item_name}
                                    </div>
                                    <div className="fw-semibold fs-7">
                                        Brand: {item.brand || "---"}
                                    </div>
                                    <div className="fw-semibold fs-7">
                                        Type: {item.type || "---"}
                                    </div>
                                    <div className="text-muted fs-7">
                                        Part Number: {item.article_no || "---"}
                                    </div>
                                </div>
                            </div>
                        ),
                        category_name: item.category_name,
                        unit_name: item.unit_name,
                        stock_qty: (
                            <div
                                className={`badge badge-light-${
                                    Number(item.stock_qty) > 0
                                        ? "primary"
                                        : "danger"
                                }`}
                                title="Click to update stock display"
                                onClick={() => {
                                    setModalShow(true);
                                    setDataModal({
                                        title: "Enter quantity of stock to move",
                                        text: `There are ${item.side_stock} in the side stock`,
                                        stock_qty: item.stock_qty,
                                        side_stock: item.side_stock,
                                        id: item.stock_no,
                                        type: "side_stock",
                                    });
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                {Number(item.stock_qty) > 0
                                    ? item.stock_qty
                                    : "No Stock"}
                            </div>
                        ),
                        side_stock: (
                            <div
                                className={`badge badge-light-${
                                    Number(item.side_stock) > 0
                                        ? "primary"
                                        : "danger"
                                }`}
                                title="Click to update side stock"
                                onClick={() => {
                                    setModalShow(true);
                                    setDataModal({
                                        title: "Enter quantity of stock to move",
                                        text: `There are ${item.stock_qty} stock/s in display`,
                                        stock_qty: item.stock_qty,
                                        side_stock: item.side_stock,
                                        id: item.stock_no,
                                        type: "stock_qty",
                                    });
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                {Number(item.side_stock) > 0
                                    ? item.side_stock
                                    : "No Stock"}
                            </div>
                        ),
                        purchase_rate: new Intl.NumberFormat("en-US").format(
                            item.purchase_rate
                        ),
                        stock_rate: `₱ ${new Intl.NumberFormat("en-US").format(
                            item.stock_rate
                        )}.00`,
                    });
                });
            }
        }
        setIsLoading(fetchLoading);
        setData(getData);
    }, [categoryData, isSuccess, fetchLoading]);

    return (
        <>
            <Toolbar label="Stock" breadcrumb={breadcrumb} />
            <Container>
                <Card>
                    <CardHeader
                        sx="border-0 pt-6"
                        action={
                            <>
                                {/* <CategorySearch
                                    category={category}
                                    setCategory={setCategory}
                                /> */}
                            </>
                        }
                    >
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input
                                type="text"
                                className="form-control form-control-solid w-300px ps-15"
                                placeholder="Search Product"
                                onChange={(e) => {
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        setSearch(e.target.value);
                                        setActive(1);
                                        setIsLoading(false);
                                    }, 1000);
                                }}
                            />
                        </div>
                    </CardHeader>
                    <CardBody sx="pt-0">
                        <Table
                            head={header}
                            body={data}
                            isLoading={isLoading}
                            active={active}
                            setActive={setActive}
                            totalPage={totalPagination}
                            activePagination={activePagination}
                            setActivePagination={setActivePagination}
                            sort={sort}
                            setSort={setSort}
                        />
                    </CardBody>
                </Card>
            </Container>
            <MoveQuantity
                show={modalShow}
                setShow={setModalShow}
                dataModal={dataModal}
            />
        </>
    );
};
